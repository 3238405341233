import React, { useState } from "react";
import {
  Collapse,
  Navbar,
  NavbarToggler,
  NavbarBrand,
  Nav,
  NavItem,
  NavbarText,
} from "reactstrap";
import { NavLink, useHistory } from "react-router-dom";
import { FaLockOpen } from "react-icons/fa";
import { useDispatch, useSelector } from "react-redux";
import { logout } from "../../redux/actions/auth";
import { hasAccess } from "../utilities";
import "./navStyle.css";
import { accessData } from "../../redux/actions/api";

function NavBar() {
  const [isOpen, setIsOpen] = useState(false);
  const dispatch = useDispatch();
  const history = useHistory();

  const toggle = () => setIsOpen(!isOpen);
  const gotoLogin = () => history.push("/login");
  const _logout = () => dispatch(logout(gotoLogin()));

  const user = useSelector((state) => state.auth.user);
  const activeBusiness = useSelector((state) => state.auth.activeBusiness);

  return (
    <Navbar
      dark
      expand="lg"
      style={{
        minHeight: "50px",
        padding: 0,
        margin: 0,
        paddingRight: 5,
        backgroundColor: activeBusiness?.primary_color,
      }}
      className="mb-1"
    >
      <NavbarBrand
        onClick={() => history.push("/app")}
        style={{ fontFamily: "cursive", cursor: "pointer" }}
        className="ml-2 mr-2 "
      >
        <img
          src={require("../../images/logo12.png").default}
          alt="inventria logo"
          className="mr-0 pr-0"
          style={{ height: "40px" }}
        />
      </NavbarBrand>
      <div>
        <NavbarToggler onClick={toggle} />
      </div>

      <Collapse isOpen={isOpen} navbar>
        <Nav className="mr-auto justify-content-between" navbar>
          {accessData.map((item) => {
            if (user.accessTo && hasAccess(user, [item.head.name])) {
              return (
                <NavItem className="navItem">
                  <NavLink to={item.head.route} className="nav">
                    {item.head.icon}
                    {item.head.name}
                  </NavLink>
                </NavItem>
              );
            }
            return null;
          })}
        </Nav>
        <NavbarText
          className="bg-danger px-3 text-white font-weight-bold"
          onClick={_logout}
          style={{ cursor: "pointer", borderRadius: 5 }}
        >
          <FaLockOpen size={25} style={{ marginRight: 5, paddingLeft: 7 }} />{" "}
          Logout
        </NavbarText>
      </Collapse>
    </Navbar>
  );
}

export default NavBar;
