import React from "react";
import { useState } from "react";
import { CardBody, Col, Container, Input, Label, Row } from "reactstrap";
import CustomCard from "../components/CustomCard";
import CustomButton from "../../app/components/Button";
import { _postApi } from "../../redux/actions/api";
import { useSelector } from "react-redux";
import { _customNotify, _warningNotify } from "../../redux/helper";
import moment from "moment";
import { useHistory } from "react-router-dom/cjs/react-router-dom";
export default function Memo() {
  const { user = {}, activeBusiness = {} } = useSelector((state) => state.auth);
  const _form = {
    date: moment().format("YYYY-MM-DD"),
    busName: "",
    purpose: "",
    from: "",
  };
  const history = useHistory();
  const [loading, setLoading] = useState(false);
  const [form, setForm] = useState(_form);

  const handleChange = ({ target: { name, value } }) => {
    setForm((p) => ({ ...p, [name]: value }));
  };
  const handleAdd = () => {
    setLoading(true);
    _postApi(
      "/account/insert-memo",
      {
        ...form,
        prefix: activeBusiness.prefix,
        raise_by: user.fullname || user.username,
        busName: form.from,
        logStatus: "approved",
        user_id: user.id,
      },
      (res) => {
        if (res.success) {
          _customNotify("Successfully Submit");
          setLoading(false);
          history.goBack();
        }
      },
      (err) => {
        _warningNotify("error occured");
        console.log(err);
        setLoading(false);
      }
    );
  };

  return (
    <div>
      <CustomCard header="Memo">
        <Container>
          <CardBody>
            <Row>
              <Col md={6}>
                <Label>From</Label>
                <Input
                  type="text"
                  name="from"
                  value={user.from}
                  onChange={handleChange}
                />
              </Col>
              <Col md={6}>
                <Label>Date</Label>
                <Input
                  type="date"
                  name="date"
                  value={form.date}
                  onChange={handleChange}
                />
              </Col>
              <Col md={12}>
                <Label>Purpose</Label>
                <Input
                  onChange={handleChange}
                  name="purpose"
                  value={form.purpose}
                  type="textarea"
                />
              </Col>
              <Col md={6}></Col>
            </Row>
            <center>
              <CustomButton
                loading={loading}
                className="mt-3"
                onClick={handleAdd}
              >
                Create
              </CustomButton>
            </center>
          </CardBody>
        </Container>
      </CustomCard>
    </div>
  );
}
