import React from "react";
import { useSelector } from "react-redux";
import { Card, CardBody, CardFooter, CardHeader } from "reactstrap";
import BackButton from "../../app/components/BackButton";

function CustomCard(props) {
  const { header, footer, back, headerRight } = props;
  const activeBusiness = useSelector((state) => state.auth.activeBusiness);

  return (
    <Card
      className={`${props.container}`}
      // outline
      style={{
        borderWidth: 2,
        borderColor: activeBusiness?.primary_color,
        borderStyle: "solid",
      }}
    >
      {header && (
        <CardHeader
          // className={`py-2`}
          style={{
            borderBottom: `1px solid ${activeBusiness?.primary_color}`,
            backgroundColor: activeBusiness?.primary_color,
            color: activeBusiness.secondary_color,
            paddingTop: ".4rem",
            paddingBottom: ".4rem",
          }}
          className={back ? "row m-0 align-items-center" : ""}
        >
          {back && (
            <div className="col-md-3">
              <BackButton />
            </div>
          )}
          <h4 className={back ? "col-md-6 text-center" : "text-center"}>
            {header}
          </h4>
          {/* <div className={back ? 'col-md-6' : ''}>{header}</div> */}
          {headerRight && <div className="col-md-3">{headerRight}</div>}
        </CardHeader>
      )}
      <CardBody className={props.body}>{props.children}</CardBody>
      {footer && (
        <CardFooter
          style={{
            borderBottom: `1px solid ${activeBusiness?.primary_color}`,
            backgroundColor: activeBusiness?.primary_color,
            color: activeBusiness.secondary_color,
            // paddingTop: '.4rem',
            // paddingBottom: '.4rem',
          }}
        >
          {footer}
        </CardFooter>
      )}
    </Card>
  );
}

export default CustomCard;
