import React from "react";
import { FaSearch } from "react-icons/fa";
import { useSelector } from "react-redux";
import "./search.css";
// import { SearchInput } from 'evergreen-ui';

export default function SearchBar({
  placeholder = "",
  filterText = "",
  onFilterTextChange = (f) => f,
  _ref,
}) {
  const handleFilterTextChange = (e) => {
    onFilterTextChange(e.target.value);
  };
  const activeBusiness = useSelector((state) => state.auth.activeBusiness);

  return (
    <div className="form-group has-search">
      <span className="form-control-feedback">
        <FaSearch />
      </span>
      <input
        ref={_ref}
        name="filterText"
        value={filterText}
        onChange={handleFilterTextChange}
        type="text"
        className="form-control"
        style={{
          borderWidth: 2,
          borderColor: activeBusiness?.primary_color,
        }}
        placeholder={
          placeholder !== "" ? placeholder : "Search for a patient..."
        }
      />
    </div>
  );
}
