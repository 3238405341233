import React from "react";
import { Row, Col } from "reactstrap";
import { Switch, Route, Redirect } from "react-router-dom";
import SupplierForm from "./suppliers/SupplierForm";
import VerticalMenu from "../components/vertical-menu/VerticalMenu";
import HorizontalMenu from "../components/vertical-menu/HorizontalMenu";
// import SupplierPerformance from '../app/account/SupplierPerformance';
// import InvertoryOverView from '../app/account/InventoryOverview';
import { FaArchway } from "react-icons/fa";
// import DailySale from '../app/account/DailySales';
import CreatePurchase from "./purchase/CreatePurchase";
import SupplierTable from "./suppliers/Index";
import SupplierPayment from "./suppliers/SupplierPayment";
import PurchaseTable from "./purchase/PurchaseTable";
import SupplierReport from "./suppliers/SupplierReport";
import { useSelector } from "react-redux";
import { hasAccess, hasSubAccess } from "../app/utilities";
// import SupplierReport from './suppliers/SupplierReport';
import { accessData } from "../redux/actions/api";

const Tabs = () => {
  const user = useSelector((state) => state.auth.user);
  const accessValues = accessData.filter(
    (item) => item.head.name === "Purchase"
  );
 
  const SidebarRender = ({ user }) => {
    const arr = [];
  
    accessValues.forEach((item) => {
      if (item.subitem) {
        item.subitem.forEach((subItem) => {
          if (user.functionalities && hasSubAccess(user, [subItem.name])) {
            arr.push(
              <HorizontalMenu key={subItem.route} route={subItem.route}>
                {subItem.icon}
                {subItem.name}
              </HorizontalMenu>
            );
          }
        });
      }
    });
  
    return <>{arr}</>;
  };

  return (
    <React.Fragment>
      <VerticalMenu title="Menu Items">
        {/* {JSON.stringify(accessValues)} */}

        <SidebarRender user={user}/>
        {/* {accessValues.map((item, idx) => {
          if (
            user.functionalities &&
            hasSubAccess(user, [item.subitem[idx].name])
          ) {
            item.subitem.map((item) => (
              <HorizontalMenu key={item.id} route={item.route}>
                {item.icon}
                {item.name}
              </HorizontalMenu>
            ));
          }
          return null;
        })} */}


        {/* {user.functionalities
          ? hasSubAccess(user, ["Purchase list"]) && (
              <HorizontalMenu route="/app/purchase/purchase-list">
                <FaArchway size={26} style={{ marginRight: 5 }} />
                Purchase List
              </HorizontalMenu>
            )
          : null}
        {user.functionalities
          ? hasSubAccess(user, ["Suppliers"]) && (
              <HorizontalMenu route="/app/purchase/suppliers">
                <FaArchway size={26} style={{ marginRight: 5 }} />
                Suppliers
              </HorizontalMenu>
            )
          : null} */}
        {/* <HorizontalMenu route="/app/purchase/create-supplier">
          <FaArchway size={26} style={{ marginRight: 5 }} />
          Create Supplier
        </HorizontalMenu> */}
      </VerticalMenu>
    </React.Fragment>
  );
};

function Purchase() {
  return (
    <Row className="m-0 p-0">
      <Col md={3} className="m-0 p-0">
        <Tabs />
      </Col>
      <Col md={9} className="">
        <Switch>
          <Redirect
            exact
            from="/app/purchase"
            to="/app/purchase/purchase-list"
          />
          <Route
            exact
            path="/app/purchase/suppliers/new"
            component={SupplierForm}
          />
          <Route
            exact
            path="/app/purchase/suppliers/payment"
            component={SupplierPayment}
          />
          <Route
            exact
            path="/app/purchase/suppliers/report/:id?"
            component={SupplierReport}
          />
          {/* <Route
            exact
            path="/app/purchase/returned-goods"
            component={ReturnedGoodsReport}
          /> */}
          {/* <Route exact path="/app/purchase/daily/chart" component={DailySale} /> */}
          <Route
            exact
            path="/app/purchase/suppliers"
            component={SupplierTable}
          />
          {/* <Route
            exact
            path="/app/purchase/supplier/performance"
            component={SupplierPerformance}
          /> */}
          <Route
            exact
            path="/app/purchase/purchase-list/new"
            component={CreatePurchase}
          />
          <Route
            exact
            path="/app/purchase/purchase-list"
            component={PurchaseTable}
          />{" "}
          <Route
            exact
            path="/app/purchase/suppliers-report/:query_type"
            component={SupplierReport}
          />
        </Switch>
      </Col>
    </Row>
  );
}

export default Purchase;
