import React, { useCallback, useEffect, useState } from "react";
import CustomCard from "../components/CustomCard";
import { Alert, Badge, Button, Row } from "reactstrap";
import CustomTable from "../../components/CustomTable";
import { useSelector } from "react-redux";
import { _fetchApi } from "../../redux/actions/api";
import { GiCancel } from "react-icons/gi";
import { FaCheck, FaEye, FaViber } from "react-icons/fa";
import CustomButton from "../../app/components/Button";
import moment from "moment";
import CustomModal from "../components/CustomModal";
import { View, Text } from "@react-pdf/renderer";
import Loading from "../../app/components/Loading";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";

function PaymentVoucher() {
  const { activeBusiness, user } = useSelector((state) => state.auth);
  const [loading, setLoading] = useState(false);
  const [memos, setMemos] = useState([]);
  const getMemos = useCallback(() => {
    _fetchApi(
      `/account/get-memo/${activeBusiness.id}/Approval/${user.id}/list`,
      (data) => {
        setLoading(false);
        if (data.success) {
          setMemos(data?.results);
        }
      },
      (err) => {
        setLoading(false);
        console.log(err);
      }
    );
  }, [activeBusiness.id, user.id]);
  useEffect(() => {
    getMemos();
  }, [getMemos]);

  const [isOpen, setIsOpen] = useState(false);
  const [items, setItems] = useState({});
  const toggle = (item) => {
    setItems(item);
    setIsOpen(!isOpen);
  };
  const fields = [
    {
      title: "Date",
      custom: true,
      component: (item) => (
        <div className="text-left">{moment().format(item.date)}</div>
      ),
    },
    {
      title: "Memo No.",
      custom: true,
      component: (item) => <div className="text-center">{item.memo_id}</div>,
    },
    {
      title: "From",
      custom: true,
      component: (item) => <>{item.from_name}</>,
    },

    {
      title: "Status",
      custom: true,
      component: (item) => (
        <div className="text-center">
          <Badge color="success">{item.status}</Badge>
        </div>
      ),
    },

    {
      title: "Action",
      custom: true,
      component: (item) => (
        <div className="text-center">
          <></>
          <CustomButton
            color="success"
            size="sm"
            className="m-1"
            handleSubmit={() => {
              history.push(
                `/app/account/memo-pdf/${item.id}?id=${item.memo_id}`
              );
            }}
          >
            <FaEye size="20" /> View Memo
          </CustomButton>
          <CustomButton
            color="success"
            size="sm"
            className="m-1"
            handleSubmit={() => {
              history.push(`/app/account/create-pv?id=${item.id}`);
            }}
          >
            Create Pv
          </CustomButton>
        </div>
      ),
    },
  ];
  const history = useHistory();
  return (
    <CustomCard header="Payment Voucher">
      {/* {JSON.stringify(memos)} */}
      {/* <CustomButton
        size="sm"
        color="primary"
        className="mb-1"
        onClick={() => {
          history.push("/app/account/memo");
        }}
      >
        Add new memo
      </CustomButton> */}
      <Row>
        {loading && <Loading />}
        {!loading ? (
          <CustomTable data={memos} fields={fields} />
        ) : (
          <center>
            <Alert className="mt-3" color="info">
              No data to view
            </Alert>
          </center>
        )}
      </Row>
      <CustomModal isOpen={isOpen} toggle={toggle} header="Preview">
        <div>
          <div
            style={{
              borderWidth: 1,
              borderColor: "#000",
              padding: 20,
              marginBottom: 20,
            }}
          >
            <h2
              style={{
                fontSize: 18,
                textAlign: "center",
                marginBottom: 10,
                textTransform: "uppercase",
              }}
            >
              {activeBusiness.business_name}
            </h2>
            <h4
              style={{
                fontSize: 12,
                textAlign: "center",
                textTransform: "uppercase",
              }}
            >
              Internal Memo
            </h4>
          </div>

          <div style={{ marginBottom: 20 }}>
            <div style={{ flexDirection: "row", width: "100%" }}>
              <div
                style={{
                  marginBottom: 10,
                  fontSize: 12,
                  textTransform: "uppercase",
                  marginRight: 10,
                }}
              >
                The Managing Director,
              </div>
            </div>
            <div style={{ flexDirection: "row", width: "100%" }}>
              <div
                style={{
                  marginBottom: 10,
                  fontSize: 12,
                  textTransform: "uppercase",
                  marginRight: 10,
                }}
              >
                From: <b>{items?.from_name}</b>
              </div>
            </div>
            <div style={{ flexDirection: "row", width: "100%" }}>
              <div
                style={{
                  marginBottom: 10,
                  fontSize: 12,
                  textTransform: "uppercase",
                  marginRight: 10,
                }}
              >
                Date: <b>{moment().format(items?.date)}</b>
              </div>
            </div>
          </div>

          <div
            style={{ flexDirection: "column", width: "100%", marginBottom: 60 }}
          >
            <div style={{ flexDirection: "row", width: "100%" }}>
              <div
                style={{
                  marginBottom: 10,
                  fontSize: 14,

                  textTransform: "uppercase",
                  marginRight: 10,
                }}
              >
                Purpose: <b>{items?.purpose}</b>
              </div>
            </div>
          </div>
        </div>
      </CustomModal>
    </CustomCard>
  );
}

export default PaymentVoucher;
