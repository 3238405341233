import React, { useCallback, useEffect } from "react";
import { useState } from "react";
import {
  CardBody,
  Col,
  Container,
  Input,
  Label,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Row,
} from "reactstrap";
import CustomCard from "../components/CustomCard";
import CustomButton from "../../app/components/Button";
import { _fetchApi, _postApi, apiURL } from "../../redux/actions/api";
import { useSelector } from "react-redux";
import { _customNotify, _warningNotify } from "../../redux/helper";
import moment from "moment";
import { useHistory } from "react-router-dom/cjs/react-router-dom";
import BackButton from "../../app/components/BackButton";
import SelectInput from "../components/SelectInput";
import useQuery from "../hooks/useQuery";
import TextInput from "../components/TextInput";
import { formatNumber, formatNumber1 } from "../../app/utilities";
import CustomModal from "../components/CustomModal";
export default function Memo() {
  const navigate = useHistory();
  const { user, activeBusiness } = useSelector((state) => state.auth);
  const query = useQuery();
  const id = query.get("id");
  const _form = {
    mode_of_payment: "",
    amount: "",
    date: moment().format("YYYY-MM-DD"),
    purpose: "",
  };
  const [data, setData] = useState({});
  const getMemos = useCallback(() => {
    _fetchApi(
      `/account/get-memo-by-id/${activeBusiness.id}/Approval/${id}`,
      (data) => {
        setLoading(false);
        if (data.success) {
          setData(data.results[0]);
        }
      }
    );
  }, [activeBusiness.id, id]);
  useEffect(() => {
    getMemos();
  }, [getMemos]);
  const history = useHistory();
  const [loading, setLoading] = useState(false);
  const [form, setForm] = useState(_form);
  const [modal, setModal] = useState(false);
  const [error, setError] = useState("");
  const [errors, setErrors] = useState({});
  const [correct, setCorrect] = useState(false);
  const [modalLoading, setModalLoading] = useState(false);

  const handleChange = ({ target: { name, value } }) => {
    setForm((p) => ({ ...p, [name]: value }));

    if (name === "mode_of_payment" && value) {
      setErrors((prev) => ({ ...prev, mode_of_payment: "" }));
    }
    if (name === "cheque_no" && value) {
      setErrors((prev) => ({ ...prev, cheque_no: "" }));
    }
    if (name === "purpose" && value) {
      setErrors((prev) => ({ ...prev, purpose: "" }));
    }
  };

  const handleAmountChange = (e) => {
    const { name, value } = e.target;
    const numValue = parseInt(value, 10);

    if (numValue == data.amount) {
      setForm((p) => ({
        ...p,
        [name]: numValue,
      }));

      if (name === "amount" && value) {
        setErrors((prev) => ({ ...prev, amount: "" }));
      }
      setError("");
      setCorrect(true);
    } else {
      setForm((p) => ({
        ...p,
        [name]: numValue,
      }));
      if (name === "amount" && value) {
        setErrors((prev) => ({ ...prev, amount: "" }));
      }
      setError(
        `Please enter the correct amount: ${formatNumber1(data.amount)}.`
      );
      setCorrect(false);
    }
  };

  let formData = new FormData();
  const handlefile = ({ target: { name, files } }) => {
    setForm((p) => ({ ...p, [name]: files[0] }));
    formData.append("document", files[0]);
    console.log(files[0]);
  };
  console.log(form);
  console.log(formData.values());

  const toggle = () => setModal(!modal);

  const [selectedFilePL, setSelectedFilePL] = useState(null);
  const onFileChangePL = (event) => {
    setSelectedFilePL(event.target.files[0]);
  };

  // const onFileUploadPL = (e) => {
  //   e.preventDefault();
  //   setLoading(true);

  //   let _formData = new FormData();
  //   // // Update the formData object
  //   _formData.append("form",form)
  //   _formData.append("image", selectedFilePL);
  //   fetch(
  //     `${apiURL}/account/insert-pv`,
  //     {
  //       method: "PUT",
  //       body: _formData,
  //       // headers: {
  //       //   'Content-Type': 'multipart/form-data',
  //       //   accept: 'application/json',
  //       // },
  //     }
  //   )
  //     .then((raw) => raw.json())
  //     .then((res) => {
  //       if (res.success) {
  //         setLoading(false);
  //         _customNotify("Successfully Recorded");
  //         navigate.goBack()
  //       }
  //     })
  //     .catch((err) => {
  //       console.log(err);
  //       setLoading(false);
  //       _warningNotify("error occured");
  //     });
  // };

  const handleSubmit = (e) => {
    e.preventDefault();
    setLoading(true);
    _postApi(
      "/account/insert-pv",
      form,
      (res) => {
        if (res.success) {
          // alert(JSON.stringify(data))
          _customNotify("Successfully Submit");
          setLoading(false);
          setModal(!modal)
          history.push(`/app/account/payment-voucher-pdf?id=${data.id}`);
          // alert(data.id)
        }
      },
      (err) => {
        _warningNotify("error occured");
        console.log(err);
        setModal(!modal)
        setLoading(false);
      }
    );
  };

  const validateForm = () => {
    const newErrors = {};
    if (!form.mode_of_payment) {
      newErrors.mode_of_payment = "Mode of Payment is required.";
    }

    if (form.mode_of_payment === "Cheque" && !form.cheque_no) {
      newErrors.cheque_no = "Cheque No. is required for Cheque payment mode.";
    }
    if (!form.amount) {
      newErrors.amount = "Amount is required.";
    }
    if (!form.purpose) {
      newErrors.purpose = "Purpose is required.";
    }

    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const handleAdd = () => {
    if (validateForm()) {
      if (correct) {
        setLoading(true);
        setModal(!modal);
      } else {
        alert(`Please enter the correct amount ${formatNumber1(data.amount)}`);
      }
    }
    // fetch(`${apiURL}/account/insert-pv`, {
    //   method: "POST",
    //   headers: { "Content-Type": "application/json", authorization: "dfgh" },
    //   body: JSON.stringify({ ...form, formData }),
    // })
    //   .then((res) => console.log(res))
    //   .catch((err) => {
    //     _warningNotify("error occured");
    //     console.log(err);
    //     setLoading(false);
    //   });
    // _postApi(
    //       "/account/insert-pv",
    //       {
    //         ...form,
    //         formData
    //       },
    //   (res) => {
    //     if (res.success) {
    //       _customNotify("Successfully Submit");
    //       setLoading(false);
    //     //   history.goBack();
    //     }
    //   },
    //   (err) => {
    //     _warningNotify("error occured");
    //     console.log(err);
    //     setLoading(false);
    //   }
    // );
  };

  return (
    <div>
      <BackButton className="mb-2" />
      <CustomCard header="Create Pv" className="mt-1 pt-1">
        <Container>
          <CardBody>
            {/* {JSON.stringify(data)} */}
            <Row className="mb-3">
              <Col md={6} className="mb-3">
                From: <b>{data.from_name}</b>
              </Col>
              <Col md={6} className="mb-3">
                Memo ID: <b>{data.memo_id}</b>
              </Col>
              <Col md={6} className="mb-3">
                Amount: <b>{formatNumber1(data.amount)}</b>
              </Col>

              <Col md={6} className="mb-3">
                Approved by: <b>{data.approve_by}</b>
              </Col>
            </Row>
            <Row>
              <Col md={12} className="pb-3">
                <Label>
                  Mode of Payment <span className="text-danger">*</span>
                </Label>
                <SelectInput
                  options={["Cash", "POS", "Transfer", "Credit", "Cheque"]}
                  name="mode_of_payment"
                  value={form.mode_of_payment}
                  onChange={handleChange}
                />
                {errors.mode_of_payment && (
                  <p style={{ color: "red", fontSize: 10 }}>
                    {errors.mode_of_payment}
                  </p>
                )}
              </Col>
              {form.mode_of_payment === "Cheque" ? (
                <Col md={12} className="pb-3">
                  <Label>Cheque No.</Label>
                  <TextInput
                    type="text"
                    name="cheque_no"
                    value={form.cheque_no}
                    onChange={handleChange}
                  />
                  {errors.cheque_no && (
                    <p style={{ color: "red", fontSize: 10 }}>
                      {errors.cheque_no}
                    </p>
                  )}
                </Col>
              ) : null}
              <Col md={12} className="pb-3">
                <Label>
                  Amount({formatNumber1(form.amount)}){" "}
                  <span className="text-danger">*</span>
                </Label>
                <TextInput
                  type="number"
                  name="amount"
                  value={form.amount}
                  onChange={handleAmountChange}
                />
                {error && <p style={{ color: "red", fontSize: 10 }}>{error}</p>}
                {errors.amount && (
                  <p style={{ color: "red", fontSize: 10 }}>{errors.amount}</p>
                )}
              </Col>
              <Col md={12} className="pb-3">
                <Label>
                  Purpose <span className="text-danger">*</span>
                </Label>
                <TextInput
                  onChange={handleChange}
                  name="purpose"
                  value={form.purpose}
                  type="textarea"
                />
                {errors.purpose && (
                  <p style={{ color: "red", fontSize: 10 }}>{errors.purpose}</p>
                )}
              </Col>
              {/* <Col md={6} className="pb-3">
                <Label>Attached Document</Label>
                <Input
                  onChange={handlefile}
                  name="document"
                  type="file"
                  className="form-control pb-2"
                />
              </Col> */}
              {/* <Col md={6} className="pb-3">
                <Label>Attached Document</Label>
                <Input
                  onChange={handlefile}
                  name="document"
                  type="file"
                  className="form-control pb-2"
                />
              </Col> */}
            </Row>
            <center>
              <CustomButton
                loading={loading}
                className="mt-3"
                onClick={handleAdd}
              >
                Create
              </CustomButton>
            </center>
          </CardBody>

          <CustomModal
            isOpen={modal}
            toggle={toggle}
            header="Create PV"
            footer={
              <>
                <CustomButton
                  color="success"
                  className="mr-3"
                  onClick={() => {
                    setModal(false);
                    setLoading(false);
                  }}
                >
                  Cancel
                </CustomButton>
                <CustomButton
                  onClick={handleSubmit}
                  loading={modalLoading}
                  // disabled={form.customerName === "" ? true : false}
                >
                  Submit
                </CustomButton>
              </>
            }
          >
            <Col md={12} className="pb-3">
              <Label>
                Attached Document{" "}
                <span style={{ fontSize: 10 }}>(Not Required)</span>
              </Label>
              <input
                className=""
                type="file"
                placeholder="Premises License by PCN"
                onChange={onFileChangePL}
                defaultValue={selectedFilePL?.name}
                accept=".jpg, .png, .jpeg"
                required
              />
            </Col>
          </CustomModal>
        </Container>
      </CustomCard>
    </div>
  );
}
