import React from "react";
import { Row, Col } from "reactstrap";
import { Switch, Route, Redirect, withRouter } from "react-router-dom";

import { AiFillAliwangwang, AiFillSetting } from "react-icons/ai";
// import POS from "./POS";
// import { MdStore } from "react-icons/md";

import VerticalMenu from "../../components/vertical-menu/VerticalMenu";
import HorizontalMenu from "../../components/vertical-menu/HorizontalMenu";
import AccountSetup from "./Account_Setup";
import UnitOfMeasure from "./UnitOfMeasurement";
import Bank from "./Bank";
// import ManageStore from "../../app/admin/ManageStore";
import PageSettingIndex from "./PageSetting/PageSettingIndex";
import { hasSubAccess } from "../../app/utilities";
import { useSelector } from "react-redux";
import { accessData } from "../../redux/actions/api";

const Tabs = withRouter(({ history, location }) => {
  const user = useSelector((state) => state.auth.user);
  const accessValues = accessData.filter(
    (item) => item.head.name === "Settings"
  );
 
  const SidebarRender = ({ user }) => {
    const arr = [];
  
    accessValues.forEach((item) => {
      if (item.subitem) {
        item.subitem.forEach((subItem) => {
          if (user.functionalities && hasSubAccess(user, [subItem.name])) {
            arr.push(
              <HorizontalMenu key={subItem.route} route={subItem.route}>
                {subItem.icon}
                {subItem.name}
              </HorizontalMenu>
            );
          }
        });
      }
    });
  
    return <>{arr}</>;
  };

  return (
    <React.Fragment>
      <VerticalMenu title="Menu Items">
        {/* <HorizontalMenu route="/app/setting/account-setup">
          <AiFillAliwangwang size={26} style={{ marginRight: 5 }} />
          Account Setup
        </HorizontalMenu> */}
        {/* <HorizontalMenu route="/app/setting/manage-store">
          <AiFillSetting size={26} style={{ marginRight: 5 }} />
          Manage Stores
        </HorizontalMenu> */}
        <SidebarRender user={user} />
        {/* {user.functionalities
          ? hasSubAccess(user, ["Unit of measurement"]) && (
              <HorizontalMenu route="/app/setting/unit-measurement">
                <AiFillSetting size={26} style={{ marginRight: 5 }} />
                Unit Of Measurement
              </HorizontalMenu>
            )
          : null} */}
        {/* <HorizontalMenu route="/app/setting/manage-user">
          <AiFillSetting size={26} style={{ marginRight: 5 }} />
          Manage User
        </HorizontalMenu> */}
        {/* <HorizontalMenu route="/app/setting/bank">
          <AiFillSetting size={26} style={{ marginRight: 5 }} />
         Bank
        </HorizontalMenu> */}
        {/* <HorizontalMenu route="/app/setting/printer">
          <AiFillSetting size={26} style={{ marginRight: 5 }} />
         Printer Setup
        </HorizontalMenu> */}
        {/* <HorizontalMenu route="/app/setting/page">
          <AiFillSetting size={26} style={{ marginRight: 5 }} />
         Page Setup
        </HorizontalMenu> */}
      </VerticalMenu>
    </React.Fragment>
  );
});

function SettingIndex() {
  return (
    <Row className="m-0">
      <Col md={3}>
        <Tabs />
      </Col>
      <Col md={9}>
        <Switch>
          <Redirect
            from="/app/setting"
            to="/app/setting/unit-measurement"
            exact
          />

          <Route
            exact
            path="/app/setting/account-setup"
            component={AccountSetup}
          />

          <Route
            exact
            path="/app/setting/unit-measurement"
            component={UnitOfMeasure}
          />
          <Route exact path="/app/setting/bank" component={Bank} />
          {/* <Route
            exact
            path="/app/setting/manage-store"
            component={ManageStore}
          /> */}
          <Route exact path="/app/setting/page" component={PageSettingIndex} />
        </Switch>
      </Col>
      <Col>{/* <ShelfAlert /> */}</Col>
    </Row>
  );
}

export default SettingIndex;
