import React from "react";
import { Typeahead } from "react-bootstrap-typeahead";
import { useSelector } from "react-redux";

function CustomTypeahead(props) {
  const {
    label,
    labelKey = "",
    options = [],
    _ref = null,
    inline = false,
  } = props;
  const activeBusiness = useSelector((state) => state.auth.activeBusiness);

  return (
    <div>
      {label && (
        <label
          className={inline ? "col-md-2 m-0 p-0 font-weight-bold mb-1" : ""}
        >
          {label}
        </label>
      )}

      <Typeahead
        // clearButton
        id={`${labelKey}-${new Date()}`}
        style={{
          border: `2px solid ${activeBusiness?.primary_color}`,
          borderRadius: 5,
        }}
        // className={inline ? 'col-md-10 rounded' : 'rounded'}
        ref={_ref}
        options={options}
        labelKey={labelKey}
        // clearButton
        {...props}
      />
    </div>
  );
}

export default CustomTypeahead;
