import React from "react";
import { useSelector } from "react-redux";

function VerticalMenu(props) {
  const activeBusiness = useSelector((state) => state.auth.activeBusiness);
  return (
    <div className="list-group ml-2 my-0">
      {props.title ? (
        <span
          className="list-group-item list-group-item-action text-center  font-weight-bold"
          style={{
            backgroundColor: activeBusiness?.primary_color,
            color: activeBusiness.secondary_color,
          }}
        >
          {props.title}
        </span>
      ) : null}
      {props.children}
    </div>
  );
}

export default VerticalMenu;
