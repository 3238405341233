import Dashboard from "../inventria/dashboard";
import Purchase from "../inventria/index";
import Sales from "../inventria/sales";
import ReportIndex from "../inventria/Report/ReportIndex";
import SettingIndex from "../inventria/setting/SettingIndex";
// import Account from "./account";
// import Production from "./production";
// import Inventory from "./inventory";
import AdminPage from "./admin";
import CustomSales from "../inventria/custom-sales";
import CustomerIndex from "./customer/index";
import Account from "../inventria/Account/Account";
import AccountIndex from "../inventria/Account/AccountIndex";

const mainRoutes = [
  {
    path: "/app/dashboard",
    component: Dashboard,
    access: ["Dashboard"],
  },
  {
    path: "/app/custom-sales",
    component: CustomSales,
    access: ["Custom Sales"],
  },
  {
    path: "/app/purchase",
    component: Purchase,
    access: ["Purchase"],
  },
  // {
  //   access: ["Purchase"],
  //   path: "/app/purchase",
  //   component: Purchase,
  // },
  {
    access: ["Sales"],
    path: "/app/sales",
    component: Sales,
  },
  // {
  //   access: ["Sales"],
  //   // access: ["Account"],
  //   path: "/app/account",
  //   component: Account,
  // },
  {
    access: ["Customer"],
    path: "/app/customer",
    component: CustomerIndex,
  },
  // {
  //   access: ["Reports"],
  //   path: "/app/account",
  //   component: Account,
  // },
  // {
  //   access: ["Production"],
  //   path: "/app/production",
  //   component: Production,
  // },
  {
    access: ["Admin"],
    path: "/app/admin",
    component: AdminPage,
  },
  // {
  //   access: ["Sales"],
  //   path: "/app/customer",
  //   component: CustomerIndex,
  // },
  {
    access: ["Settings"],
    path: "/app/setting",
    component: SettingIndex,
  },
  {
    access: ["Report"],
    path: "/app/report",
    component: ReportIndex,
  },
  {
    access: ["Account"],
    path: "/app/account",
    component: AccountIndex,
  },
  // {
  //   access: ["Sales"],
  //   component: FinanceIndex,
  //   path: "/app/finance",
  // },
];

export default mainRoutes;
