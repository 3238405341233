import React from "react";
import VerticalMenu from "../../components/vertical-menu/VerticalMenu";
import HorizontalMenu from "../../components/vertical-menu/HorizontalMenu";
import { FaStore, FaUsers } from "react-icons/fa";
import { hasSubAccess } from "../utilities";
import { useSelector } from "react-redux";
import { accessData } from "../../redux/actions/api";

function Menu() {
  const user = useSelector((state) => state.auth.user);

  const accessValues = accessData.filter(
    (item) => item.head.name === "Admin"
  );
 
  const SidebarRender = ({ user }) => {
    const arr = [];
  
    accessValues.forEach((item) => {
      if (item.subitem) {
        item.subitem.forEach((subItem) => {
          if (user.functionalities && hasSubAccess(user, [subItem.name])) {
            arr.push(
              <HorizontalMenu key={subItem.route} route={subItem.route}>
                {subItem.icon}
                {subItem.name}
              </HorizontalMenu>
            );
          }
        });
      }
    });
  
    return <>{arr}</>;
  };

  return (
    <VerticalMenu title="Menu">
      {/* <HorizontalMenu route="/app//new-user">Create User</HorizontalMenu> */}
      <SidebarRender user={user} />
      {/* {user.functionalities
        ? hasSubAccess(user, ["Manage users"]) && (
            <HorizontalMenu route="/app/admin/manage-users">
              <FaUsers
                size={26}
                fontWeight="bold"
                style={{ marginRight: 10 }}
              />
              Manage Users
            </HorizontalMenu>
          )
        : null}
      {user.functionalities
        ? hasSubAccess(user, ["Manage stores"]) && (
            <HorizontalMenu route="/app/admin/manage-stores">
              <FaStore
                size={26}
                fontWeight="bold"
                style={{ marginRight: 10 }}
              />
              Manage Stores
            </HorizontalMenu>
          )
        : null} */}

      {/* <HorizontalMenu route='/app/admin/staffreview'>
        <FaUsers size={26} fontWeight='bold' style={{ marginRight: 10 }} />
        Staff Review
      </HorizontalMenu> */}
      {/* <HorizontalMenu route='/app/admin/account-setup'>
        <FaCalculator size={26} fontWeight='bold' style={{ marginRight: 10 }} />
        Account Setup
      </HorizontalMenu> */}
      {/* <HorizontalMenu route="/app/admin/users">Creat New Facility</HorizontalMenu> */}
    </VerticalMenu>
  );
}

export default Menu;
