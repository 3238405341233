import React, { useEffect, useState } from "react";
import CheckBoxItem from "../../components/checkbox";
import { Col, FormGroup, Label, Row } from "reactstrap";
import { useDispatch, useSelector } from "react-redux";
import { getStoresList } from "../../redux/actions/stores";
import CustomTypeahead from "../../components/CustomTypeahead";
import { Typeahead } from "react-bootstrap-typeahead";
import { BsEyeSlashFill } from "react-icons/bs";
import { BiShow } from "react-icons/bi";
import CustomButton from "../components/Button";

function NewUserForm({
  handleChange,
  handleCheckboxChange,
  handleChildChechBoxChange,
  username,
  handleTypeaheadChange,
  form,
  phone,
  fullname,
  password,
  role,
  accessTo,
  multiSelections,
  setMultiSelections,
  accessData,
}) {
  const dispatch = useDispatch();
  const options = useSelector((state) => state.stores.storeList);

  useEffect(() => {
    dispatch(getStoresList());
  }, [dispatch]);

  const [showPass, setShowPass] = useState(false);
  const showPassword = () => {
    setShowPass((prev) => !prev);
  };

  return (
    <>
      <FormGroup row>
        <div className="col-md-4 col-lg-4">
          <label>Full Name</label>
          <input
            className="form-control"
            type="text"
            name="fullname"
            value={fullname}
            onChange={handleChange}
          />
        </div>
        <div className="col-md-4 col-lg-4">
          <label>Phone No.</label>
          <input
            className="form-control"
            type="phone"
            name="phone"
            value={phone}
            onChange={handleChange}
          />
        </div>
        <div className="col-md-4 col-lg-4">
          <label>Password</label>
          <div className="input-group">
            <input
              type={!showPass ? "password" : "text"}
              name="password"
              value={password}
              onChange={handleChange}
              className="form-control"
            />
            <div className="input-group-append">
              <CustomButton
                class="btn btn-success size-sm"
                onClick={showPassword}
              >
                {showPass ? <BiShow /> : <BsEyeSlashFill />}
              </CustomButton>
            </div>
          </div>
        </div>
      </FormGroup>

      <FormGroup row>
        <div className="col-md-4 col-lg-4">
          <label>Role</label>
          <input
            onChange={handleChange}
            name="role"
            type="text"
            list="role"
            autoComplete="disabled"
            value={role}
            className="form-control"
          />
          <datalist id="role">
            <option value="Business Owner" />
            <option value="Receptionist" />
            <option value="Sales Agent" />
            <option value="Store Keeper" />
            <option value="Accountant" />
          </datalist>
        </div>
        <div className="col-md-4 col-lg-4">
          <CustomTypeahead
            label="Default store"
            options={options}
            labelKey="storeName"
            onChange={(val) => {
              if (val) {
                handleTypeaheadChange(val[0]);
              }
            }}
          />
        </div>
        <div className="col-md-4 col-lg-4">
          <Label>Assign Store</Label>
          <Typeahead
            id="basic-typeahead-multiple"
            labelKey="branch_name"
            multiple
            onChange={setMultiSelections}
            options={options}
            placeholder="Choose several store..."
            selected={multiSelections}
          />
        </div>
      </FormGroup>

      <FormGroup>
        <label>Access (User's Privilege)</label>
        <Row>
          {accessData.map((item, index) => (
            <Col md="4" key={index}>
              <div>
                <label>
                  <input
                    type="checkbox"
                    checked={form.accessTo.includes(item.head.name)}
                    onChange={() => handleCheckboxChange(item)}
                    className="mr-2"
                  />
                  <b>{item.head.name}</b>
                </label>
              </div>

              {form.accessTo.includes(item.head.name) &&
                item.subitem &&
                item.subitem.length > 0 && (
                  <div style={{ marginLeft: 20 }}>
                    {item.subitem.map((subItem, subIndex) => (
                      <div key={subIndex}>
                        <label>
                          <input
                            type="checkbox"
                            checked={form.functionalities.includes(
                              subItem.name
                            )}
                            onChange={() => handleChildChechBoxChange(subItem)}
                            className="mr-2"
                          />
                          {subItem.name}
                        </label>
                      </div>
                    ))}
                  </div>
                )}
            </Col>
          ))}
        </Row>
      </FormGroup>
    </>
  );
}

export default NewUserForm;
