import React, { useState, useEffect } from "react";
// import PropTypes from "prop-types";
// import { FaFileExcel } from "react-icons/fa";
// import { utils as XLSXUtils, writeFile as writeExcelFile } from "xlsx";
// import CustomTree from "./CustomTree2";
import { toggleExpandedForAll } from "react-sortable-tree";
// import SearchBar from "components/SearchBar";
import {
  Button,
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
} from "reactstrap";
// import { useToasts } from "react-toast-notifications";
import { FaEdit, FaEye, FaPen, FaPlus } from "react-icons/fa";
import { BsTrash } from "react-icons/bs";
import { PDFViewer } from "@react-pdf/renderer";
// import CollectionsSummaryPDF from "./StructurePdf";
import { MdCancel } from "react-icons/md";
// import StructureExcel from "./StructureExcel";
import CustomTree from "./CustomTree";

function StructureTree({
  editNode = (f) => f,
  deleteNode = (f) => f,
  addChild = (f) => f,
  treeData = [],
  title = "",
}) {
  const [searchText, setSearchText] = useState("");
  const [expanded, setExpanded] = useState(true);
  const [filteredTreeData, setFilteredTreeData] = useState(treeData);

  const handleSearchChange = (event) => {
    setSearchText(event.target.value);
  };

  useEffect(() => {
    const expandedTreeData = treeData.map((node) => ({
      ...node,
      expanded: expanded,
    }));
    setFilteredTreeData(expandedTreeData);
  }, [expanded, treeData]);

  useEffect(() => {
    if (!searchText) {
      setFilteredTreeData(treeData);
    } else {
      const filterTree = (data) =>
        data
          .map((node) => {
            if (
              node.head.toLowerCase().includes(searchText.toLowerCase()) ||
              node.description.toLowerCase().includes(searchText.toLowerCase())
            ) {
              return {
                ...node,
                expanded: true,
                children: node.children ? filterTree(node.children) : [],
              };
            }
            return null;
          })
          .filter((node) => node !== null);
      const filteredData = filterTree(treeData);
      setFilteredTreeData(filteredData);
    }
  }, [searchText, treeData]);

  function expand(expanded) {
    setFilteredTreeData(
      toggleExpandedForAll({
        treeData,
        expanded,
      })
    );
  }

  function expandAll() {
    expand(true);
  }

  function collapseAll() {
    expand(false);
  }

  const [print, setPrint] = useState(false);

  const open = () => {
    setPrint(!print);
  };

  const close = () => {
    setPrint(false);
  };

  const [dropdownOpen, setDropdownOpen] = useState(false);

  const toggle = () => setDropdownOpen((prevState) => !prevState);

  return (
    <>
      {print ? (
        <div></div>
      ) : (
        <div style={{height: "70vh"}}>
          <div className="row">
            <div className="col-md-4 ml-5 m-sm-5 mb-1 mt-0 pl-5">
              {/* <SearchBar
                placeholder="Search By code or description"
                filterText={searchText}
                onFilterTextChange={handleSearchChange}
              /> */}
            </div>
          </div>
          <div id="print_tree">
            {/* {JSON.stringify(title)} */}
            <CustomTree
              generateNodeProps={({ node, path }) => ({
                title: (
                  <span
                    style={{
                      textTransform: node.children.length
                        ? "uppercase"
                        : "capitalize",
                    }}
                  >
                    {`${node.head}-${node.description}`}
                  </span>
                ),
                buttons: [
                  <button
                    className="btn btn-primary btn-sm  no-print"
                    onClick={() => addChild(node)}
                    style={{ marginRight: "5px", color: "white" }}
                    key="addChild"
                  >
                    <FaPlus />
                  </button>,

                  <button
                    className="btn btn-primary btn-sm  no-print"
                    onClick={() => editNode(node)}
                    style={{ marginRight: "5px", color: "white" }}
                    key="editNode"
                  >
                    <FaEdit />
                  </button>,

                  !node.children.length && (
                    <button
                      className="btn btn-danger btn-sm no-print"
                      onClick={() => deleteNode(node)} // Use deleteNode prop
                      key="deleteNode"
                    >
                      <BsTrash />
                    </button>
                  ),
                ],
              })}
              treeData={filteredTreeData}
            />
          </div>
        </div>
      )}
    </>
  );
}

export default StructureTree;
