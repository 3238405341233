import React from "react";
import Select from "react-select";

const CommonSelect = ({
  options = [],
  className = "",
  value = "",
  onChange = (f) => f,
}) => {
  return (
    <>

      <Select
        classNamePrefix="react-select"
        className={className}
        options={options}
        defaultValue={value}
        onChange={onChange}
        placeholder="Select"
      />
    </>
  );
};

export default CommonSelect;
