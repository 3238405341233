import React, { useCallback, useEffect, useState } from "react";
import {
  Table,
  Button,
  FormGroup,
  Form,
  Col,
  Row,
  Label,
  CardBody,
  Input,
} from "reactstrap";
import { MdDelete, MdRateReview, MdEdit, MdCheck } from "react-icons/md";
// import { useHistory } from "react-router-dom";
// import { Scrollbars } from 'react-custom-scrollbars'
import {
  _fetchApi,
  _deleteApi,
  apiURL,
  _postApi,
} from "../../redux/actions/api";
import { _customNotify, _warningNotify } from "../../redux/helper";
// import SearchBar from "../../SearchBar";
import { useSelector, useDispatch } from "react-redux";
import SearchBar from "../../inventria/components/SearchBar";
// import CustomCard from '../../inventria/components/CustomCard'
import { useHistory } from "react-router";
import CustomButton from "../components/Button";
import { FaPlus } from "react-icons/fa";
import CustomCard from "../../components/CustomCard";
import useQuery from "../../inventria/hooks/useQuery";
import { Checkbox } from "evergreen-ui";
import CustomTypeahead from "../../components/CustomTypeahead";
import { Typeahead } from "react-bootstrap-typeahead";
import { BiShow } from "react-icons/bi";
import { BsEyeSlashFill } from "react-icons/bs";
import { getStoresList } from "../../redux/actions/stores";
import { accessData } from "../../redux/actions/api";

export default function User({ user }) {
  const [roles, setRoles] = useState([]);
  const [loading, setLoading] = useState(false);
  const [multiSelections, setMultiSelections] = useState([]);
  const activeBusiness = useSelector((state) => state.auth.activeBusiness);
  const access = user && user.accessTo && user.accessTo.split(",");
  const sub = user && user.functionalities && user.functionalities.split(",");

  const [form, setForm] = useState({
    ...user,
    roles: [
      "Admin",
      "Customers",
      "Inventory",
      "Administration",
      "Management",
      "Supplier",
      "Purchases",
      "Make Sales",
      "Expenses",
      "Transfer",
      "Pending Sales",
      "Reports",
      "Settings",
      "Custom Sales",
    ],
    fullname: user.fullname,
    checked: access,
    functionalities: sub || [],
    password: "",
    query_type: "update",
  });

  const options = useSelector((state) => state.stores.storeList);
  const [showPass, setShowPass] = useState(false);
  const dispatch = useDispatch();
  const history = useHistory();
  const showPassword = () => {
    setShowPass((p) => !p);
  };
  const handleChange = ({ target: { name, value } }) => {
    setForm({
      ...form,
      error: "",
      [name]: value,
    });
  };

  const handleTypeaheadChange = (val) => {
    if (val) {
      setForm((p) => ({
        ...p,
        branch_name: val.storeName,
      }));
    }
  };
  const syncRoles = useCallback(() => {
    _fetchApi(
      `/api/v1/get-users-by-facility/${activeBusiness.id}`,
      (data) => {
        setLoading(false);
        if (data.success) {
          // setUsersList(data.results)
        }
      },
      (err) => {
        setLoading(false);
        console.log(err);
      }
    );
  }, [activeBusiness.id]);

  const handleSubmit = (e) => {
    e.preventDefault();

    setLoading(true);
    const submissionData = {
      ...form,
      accessTo: form.checked.toString(),
      functionalities: form.functionalities.toString(),
    };
    // console.log(submissionData);
    _postApi(
      `/api/auth/sign-up`,
      submissionData,
      (resp) => {
        if (resp.success) {
          setLoading(false);
          _customNotify("User updated successfully");
          history.push("/app/admin/manage-users");
        }
      },
      (err) => {
        console.log("err", err);
        _warningNotify("An error occured while updating!");
      }
    );
  };

  useEffect(() => {
    dispatch(getStoresList());
    // dispatch()
    syncRoles();
  }, [dispatch, syncRoles]);
  return (
    <CustomCard back header="Manage your users">
      {/* {JSON.stringify(form)} */}
      <Col>
        <CustomButton
          onClick={() =>
            history.push("/app/admin/manage-users/new-user?type=new_user")
          }
        >
          <FaPlus className="mr-2" />
          Create New User
        </CustomButton>
        <br />
      </Col>
      <CardBody>
        {/* {JSON.stringify(user)} */}
        <Form onSubmit={handleSubmit}>
          <FormGroup row>
            <div className="col-md-4 col-lg-4">
              <label>Full Name</label>
              <input
                className="form-control"
                type="text"
                name="fullname"
                value={form.fullname}
                onChange={handleChange}
              />
            </div>
            <div className="col-md-4 col-lg-4">
              <label>Phone No.</label>
              <input
                className="form-control"
                type="phone"
                name="phone"
                value={form.phone}
                onChange={handleChange}
              />
            </div>
            <div className="col-md-4 col-lg-4">
              <label>Password</label>
              <div class="input-group">
                <input
                  type={!showPass ? "password" : "text"}
                  name="password"
                  value={form.password}
                  onChange={handleChange}
                  className="form-control "
                />
                <div class="input-group-append">
                  <CustomButton
                    class="btn btn-success size-sm"
                    onClick={showPassword}
                  >
                    {showPass ? <BiShow /> : <BsEyeSlashFill />}
                  </CustomButton>
                </div>
              </div>
            </div>
          </FormGroup>

          <FormGroup row>
            <div className="col-md-4 col-lg-4">
              <label>Role</label>
              <Input
                onChange={handleChange}
                name="role"
                type="select"
                autoComplete="disabled"
                value={form.role || user?.role}
                className="form-control"
                options={[
                  "Store Owner",
                  "Receptionist",
                  "Sales Agent",
                  "Store Keeper",
                ]}
                // placeholder="Role"
              >
                {[
                  "Store Owner",
                  "Receptionist",
                  "Sales Agent",
                  "Store Keeper",
                ].map((role, i) => (
                  <option selected={user?.role === role} key={i}>
                    {role}
                  </option>
                ))}
                {/*  <option value="Lab Technologist" /> */}
              </Input>
            </div>
            <div className="col-md-4 col-lg-4">
              <CustomTypeahead
                label="Default store"
                options={options}
                // defaultSelected={["Main Store"]}
                labelKey="storeName"
                onChange={(val) => {
                  if (val) {
                    handleTypeaheadChange(val[0]);
                  }
                }}
                defaultInputValue={options[0]?.storeName || ""}
                onInputChange={(val) => {
                  console.log(val);
                }}
              />
            </div>
            <div className="col-md-4 col-lg-4">
              <Label>Assign Store</Label>
              <Typeahead
                id="basic-typeahead-multiple"
                labelKey="branch_name"
                multiple
                onChange={(e) => {
                  setMultiSelections();
                  setForm({
                    ...form,
                    store:
                      form.store.length > 0 && e[0].storeName !== form.store
                        ? `${form.store},${e[0].storeName}`
                        : e[0].storeName,
                  });
                  console.error({ ZELENZKYY: e, form });
                }}
                onInputChange={(val) => {
                  console.error({ ZELENZKYY: val.toString() });
                }}
                options={options}
                defaultInputValue={options[0]?.store || ""}
                placeholder="Choose several store..."
                clearButton={true}
                selected={multiSelections}
              />
            </div>
          </FormGroup>

          <FormGroup className="mt-5">
            {/* {JSON.stringify(user)} */}
            <label className="fw-bold">Edit (User's Privilege)</label>
            <Row>
              {accessData?.map((module, idx) => (
                <Col md="4" key={module.head.name}>
                  <div>
                    <label>
                      <input
                        type="checkbox"
                        id="custom-switch"
                        checked={form.checked.includes(module.head.name)}
                        onChange={({ target: { checked } }) => {
                          setForm((prev) => {
                            const updatedChecked = checked
                              ? [...prev.checked, module.head.name]
                              : prev.checked.filter(
                                  (title) => title !== module.head.name
                                );

                            let updatedFunctionalities = prev.functionalities;

                            if (checked) {
                              const firstSubitem = module.subitem?.[0]?.name;
                              if (firstSubitem) {
                                updatedFunctionalities = [
                                  ...new Set([
                                    ...updatedFunctionalities,
                                    firstSubitem,
                                  ]),
                                ];
                              }
                            } 
                            
                            else {
                              updatedFunctionalities =
                                updatedFunctionalities.filter(
                                  (func) =>
                                    !(
                                      module.subitem?.map(
                                        (item) => item.name
                                      ) || []
                                    ).includes(func)
                                );
                            }

                            return {
                              ...prev,
                              checked: updatedChecked,
                              functionalities: updatedFunctionalities,
                            };
                          });
                        }}
                        className="mr-2"
                        style={{ border: "2px solid rgb(66, 103, 178)" }}
                      />
                      <b>{module.head.name}</b>
                    </label>
                  </div>

                  {form.checked.includes(module.head.name) &&
                    module.subitem?.length > 0 && (
                      <div style={{ marginLeft: 20 }}>
                        {module?.subitem?.map((item, subIdx) => (
                          <div key={item.name}>
                            <label>
                              <input
                                type="checkbox"
                                id="custom-switch"
                                checked={form.functionalities.includes(
                                  item.name
                                )}
                                onChange={({ target: { checked } }) => {
                                  setForm((prev) => {
                                    const updatedFunctionalities = checked
                                      ? [
                                          ...new Set([
                                            ...prev.functionalities,
                                            item.name,
                                          ]),
                                        ]
                                      : prev.functionalities.filter(
                                          (func) => func !== item.name
                                        );

                                    return {
                                      ...prev,
                                      functionalities: updatedFunctionalities,
                                    };
                                  });
                                }}
                                className="mr-2"
                              />
                              {item.name}
                            </label>
                          </div>
                        ))}
                      </div>
                    )}
                </Col>
              ))}
            </Row>
          </FormGroup>

          <Row>
            <Col md={12} className="text-center">
              <CustomButton
                color="success"
                size="sm"
                loading={loading}
                onClick={(e) => handleSubmit(e)}
              >
                <MdCheck size={20} fontWeight="bold" />
                Save changes
              </CustomButton>
            </Col>
          </Row>
        </Form>
      </CardBody>
    </CustomCard>
  );
}
