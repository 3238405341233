import React, { useEffect } from "react";
import { Row, Col } from "reactstrap";
import { Switch, Route, Redirect, withRouter } from "react-router-dom";

import {
  AiFillAliwangwang,
  AiFillBook,
  AiFillDashboard,
  AiFillExperiment,
  AiFillreport,
} from "react-icons/ai";
// import POS from "./POS";

import VerticalMenu from "../../components/vertical-menu/VerticalMenu";
import HorizontalMenu from "../../components/vertical-menu/HorizontalMenu";
import DailyReport from "./DailyReport";
import TransactionReport from "./TransactionRep";
import BankReport from "./BankReport";
import ExpensesReport from "./ExpensesReport";
import Expenses from "./Expenses";
import { getStoresList } from "../../redux/actions/stores";
import { useDispatch, useSelector } from "react-redux";
import Dashboard from "../dashboard";
import { FiDownloadCloud, FiRotateCcw } from "react-icons/fi";
import InvoiceForm from "./InvoiceForm";
import ViewInvoice from "./ViewInvoice";
import { hasSubAccess } from "../../app/utilities";
import { accessData } from "../../redux/actions/api";
// import AccountSetup from "./Account_Setup";
// import UnitOfMeasure from "./UnitOfMeasurement";

const Tabs = withRouter(({ history, location }) => {
  const user = useSelector((state) => state.auth.user);

  const accessValues = accessData.filter(
    (item) => item.head.name === "Reports"
  );
 
  const SidebarRender = ({ user }) => {
    const arr = [];
  
    accessValues.forEach((item) => {
      if (item.subitem) {
        item.subitem.forEach((subItem) => {
          if (user.functionalities && hasSubAccess(user, [subItem.name])) {
            arr.push(
              <HorizontalMenu key={subItem.route} route={subItem.route}>
                {subItem.icon}
                {subItem.name}
              </HorizontalMenu>
            );
          }
        });
      }
    });
  
    return <>{arr}</>;
  };

  return (
    <React.Fragment>
      <VerticalMenu title="Menu Items">
        {/* <HorizontalMenu route="/app/report/daily-report">
          <AiFillDashboard size={26} style={{ marginRight: 5 }} />
          Daily Sales
        </HorizontalMenu> */}
        <SidebarRender user={user}/>
        {/* {user.functionalities
          ? hasSubAccess(user, ["Generate invoice"]) && (
              <HorizontalMenu route="/app/report/invoice-report">
                <AiFillBook size={26} style={{ marginRight: 5 }} />
                Generate Invoice
              </HorizontalMenu>
            )
          : null} */}
        {/* <HorizontalMenu route="/app/report/bank-report">
          <FiRotateCcw size={26} style={{ marginRight: 5 }} />
          Bank Report
        </HorizontalMenu> */}
        {/* {user.functionalities
          ? hasSubAccess(user, ["Expenses report"]) && (
              <HorizontalMenu route="/app/report/expenses">
                <AiFillExperiment size={26} style={{ marginRight: 5 }} />
                Expenses Report
              </HorizontalMenu>
            )
          : null} */}
        {/* <HorizontalMenu route="/app/dashboard">
          <FiDownloadCloud size={26} style={{ marginRight: 5 }} />
          Dashboard
        </HorizontalMenu> */}
      </VerticalMenu>
    </React.Fragment>
  );
});

function ReportIndex() {
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(getStoresList());
  }, [dispatch]);

  return (
    <Row className="m-0">
      <Col md={3}>
        <Tabs />
      </Col>
      <Col md={9}>
        <Switch>
          <Redirect from="/app/report" to="/app/report/expenses" exact />
          {/* 
          {/* <Route
            exact
            path="/app/report/account-setup"
            component={AccountSetup}
          /> */}
          <Route
            exact
            path="/app/report/daily-report"
            component={DailyReport}
          />
          <Route
            exact
            path="/app/report/invoice-report"
            component={TransactionReport}
          />
          <Route exact path="/app/report/bank-report" component={BankReport} />
          <Route
            exact
            path="/app/report/expenses"
            component={ExpensesReport}
          />{" "}
          <Route exact path="/app/report/expenses/new" component={Expenses} />
          <Route path="/app/dashboard" component={Dashboard} />
          <Route
            path="/app/report/invoice-report/new"
            component={InvoiceForm}
          />
          <Route
            path="/app/report/invoice-report/view"
            component={ViewInvoice}
          />
        </Switch>
      </Col>
      <Col>{/* <ShelfAlert /> */}</Col>
    </Row>
  );
}

export default ReportIndex;
