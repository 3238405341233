import {
  MdAccountBalance,
  MdAccountBalanceWallet,
  MdDashboard,
} from "react-icons/md";
import store from "../store";
import {
  FaArchway,
  FaCartPlus,
  FaRegFileAlt,
  FaStore,
  FaUserAlt,
  FaUsers,
} from "react-icons/fa";
import {
  AiFillBook,
  AiFillExperiment,
  AiFillReconciliation,
  AiFillSetting,
  AiFillSnippets,
  AiOutlineCreditCard,
  AiOutlineInsertRowAbove,
  AiOutlinePartition,
  AiOutlineSwap,
  AiOutlineTeam,
} from "react-icons/ai";
import { GiPayMoney } from "react-icons/gi";

let localEndpoint = "http://localhost:42790";
let remoteEndpoint = "https://server.brainstorm.ng/inventria";
export const ipAddr = "127.0.0.1";
export const apiURL =
  process.env.NODE_ENV === "production" ? remoteEndpoint : remoteEndpoint;

const token = localStorage.getItem("@@__token");
export function toWordsconver(s) {
  // / System for American Numbering
  var th_val = ["", "thousand", "million", "billion", "trillion"];
  // System for uncomment this line for Number of English
  // var th_val = ['','thousand','million', 'milliard','billion'];

  var dg_val = [
    "zero",
    "one",
    "two",
    "three",
    "four",
    "five",
    "six",
    "seven",
    "eight",
    "nine",
  ];
  var tn_val = [
    "ten",
    "eleven",
    "twelve",
    "thirteen",
    "fourteen",
    "fifteen",
    "sixteen",
    "seventeen",
    "eighteen",
    "nineteen",
  ];
  var tw_val = [
    "twenty",
    "thirty",
    "forty",
    "fifty",
    "sixty",
    "seventy",
    "eighty",
    "ninety",
  ];
  if (s) {
    s = s.toString() || 0;
    s = s.replace(/[\\, ]/g, "");
    if (s != parseInt(s)) return "not a number ";
    var x_val = s.indexOf(".");
    if (x_val == -1) x_val = s.length;
    if (x_val > 15) return "too big";
    var n_val = s.split("");
    var str_val = "";
    var sk_val = 0;
    for (var i = 0; i < x_val; i++) {
      if ((x_val - i) % 3 == 2) {
        if (n_val[i] == "1") {
          str_val += tn_val[Number(n_val[i + 1])] + " ";
          i++;
          sk_val = 1;
        } else if (n_val[i] != 0) {
          str_val += tw_val[n_val[i] - 2] + " ";
          sk_val = 1;
        }
      } else if (n_val[i] != 0) {
        str_val += dg_val[n_val[i]] + " ";
        if ((x_val - i) % 3 == 0) str_val += "hundred ";
        sk_val = 1;
      }
      if ((x_val - i) % 3 == 1) {
        if (sk_val) str_val += th_val[(x_val - i - 1) / 3] + " ";
        sk_val = 0;
      }
    }
    if (x_val != s.length) {
      var y_val = s.length;
      str_val += "point ";
      for (var e = x_val + 1; e < y_val; e++) str_val += dg_val[n_val[e]] + " ";
    }
    return str_val.replace(/\s+/g, " ");
  }
}
const _postApi = (url, data = {}, success = (f) => f, error = (f) => f) => {
  const facilityId = store.getState().auth.activeBusiness.id;

  data.facilityId = facilityId;
  fetch(`${apiURL}${url}`, {
    method: "POST",
    headers: { "Content-Type": "application/json", authorization: token },
    body: JSON.stringify(data),
  })
    .then((res) => res.json())
    .then((response) => {
      console.log(response);
      if (response.status >= 400) {
        error(response);
      } else success(response);
    })
    .catch((err) => error(err));
};

const _fetchApi = (
  url,
  success = (f) => f,
  error = (f) => f,
  empty = (f) => f
) => {
  fetch(`${apiURL}${url}`, {
    method: "GET",
    headers: { "Content-Type": "application/json", authorization: token },
  })
    .then((raw) => raw.json())
    .then((response) => {
      if (response) {
        success(response);
      } else {
        console.log("Empty response");
        empty();
      }
    })
    .catch((err) => {
      error(err);
    });
};

const _deleteApi = (
  route,
  data = {},
  callback = (f) => f,
  err_cb = (f) => f
) => {
  // const { facilityId, username } = store.getState().auth.user;
  // data.facilityId = facilityId;
  // data.userId = username;

  fetch(`${apiURL}${route}`, {
    method: "DELETE",
    headers: { "Content-Type": "application/json" },
    body: data ? JSON.stringify(data) : null,
  })
    .then(function (response) {
      // if the status of the response is greater than 400, then error is returned
      if (response.status >= 400) {
        if (err_cb) err_cb();
      }
      if (callback) callback();
    })
    .catch(function (err) {
      return err_cb(err);
    });
};

function unflatten(arr) {
  var tree = [],
    mappedArr = {},
    arrElem,
    mappedElem;

  // First map the nodes of the array to an object -> create a hash table.
  for (var i = 0, len = arr?.length; i < len; i++) {
    arrElem = arr[i];
    mappedArr[arrElem.head] = arrElem;
    mappedArr[arrElem.head]["children"] = [];
  }

  // Create the tree structure by iterating over the array again.
  for (var i = 0, len = arr?.length; i < len; i++) {
    arrElem = arr[i];
    mappedElem = mappedArr[arrElem.head];

    // Add a custom property to each child containing parent head values
    const parentHeads = [];
    let currentParent = mappedElem;
    while (currentParent?.subhead) {
      parentHeads.unshift(currentParent?.subhead);
      currentParent = mappedArr[currentParent?.subhead];
    }
    const childWithProperty = { ...mappedElem, parentHeads };

    if (mappedElem?.subhead) {
      mappedArr[`parent_${i}`] = "20";
      mappedArr[mappedElem?.subhead]["children"]?.push(childWithProperty);
    } else {
      tree.push(childWithProperty);
    }
  }

  return tree;
}

export { _postApi, _fetchApi, _deleteApi, unflatten };

// // export const apiURL = `http://${ipAddr}:42426`
// // export const apiURL = 'http://192.168.0.108:42426'
// // export const apiURL = 'http://192.168.43.253:42426'
// // export const apiURL = 'http://192.168.2.108:48485/api/v1';
// // export const apiURL = 'https://pscprime.com/test/inventria-server'
// // export const apiURL = 'http://127.0.0.1:42426';
// // export const apiURL = 'http://127.0.0.1:42423/api'
// // let localEndpoint = "http://localhost:42426";
// // let remoteEndpoint = "https://yge.wvi.mybluehost.me/test/inventria-server";
// // export const apiURL = process.env.NODE_ENV=== 'production' ? remoteEndpoint : localEndpoint
// export const apiURL = "https://yge.wvi.mybluehost.me/test/inventria-server";

// /**
//  * _fetchApi()
//  * helper function that fetches data from the database using a
//  * specified route and performs the callback function on the returned data
//  * @params url (string) => the api route
//  * @params success (func) => the action to perform on that data successful return
//  * @params error (func) => the action to perform on failure event
//  * @params empty (func) => the action to perform if respose is empty
//  *      that is being returned
//  */
// const token = localStorage.getItem("@@__token");
// const _fetchApi = (
//   url,
//   success = (f) => f,
//   error = (f) => f,
//   empty = (f) => f,
// ) => {
//   //   const { facilityId } = store.getState().auth.user;
//   //   let actualURL = `${url}/${facilityId}`
//   fetch(url)
//     .then((raw) => raw.json())
//     .then((response) => {
//       console.log(url);
//       if (response) {
//         success(response);
//       } else {
//         console.log("Empty response");
//         empty();
//       }
//     })
//     .catch((err) => {
//       console.log(url);
//       error(err);
//     });
// };

// /**
//  * _postApi()
//  * An helper function that posts data to the database
//  * @params route (string) => the api route to submit on
//  * @params data (object) => item to be submitted
//  * @params callback => optional callback function
//  */
// const _postApi = (url, data = {}, success = (f) => f, error = (f) => f) => {
//   //   const { facilityId } = store.getState().auth.user;
//   //   data.facilityId = facilityId;
//   const created_by = "test_user";
//   const obj = { ...data, created_by };
//   // console.log('here', obj);

//   fetch(url, {
//     method: "POST",
//     headers: {
//       headers: { "Content-Type": "application/json", authorization: token },
//     },
//     body: JSON.stringify(obj),
//   })
//     .then((resp) => resp.json())
//     .then((response) => {
//       console.log(response);
//       success(response);
//     })
//     .catch((err) => {
//       console.log(url);
//       error(err);
//     });
// };

// /**
//  * _deleteData()
//  * An helper function that deletes data from the database
//  * @params route (String) => the api route
//  * @params data (object) => object containing the details of
//  * the item to be deleted
//  * @params callback (func) => optional callback
//  */
// const _deleteApi = (
//   route,
//   data = {},
//   callback = (f) => f,
//   err_cb = (f) => f,
// ) => {
//   //   const { facilityId } = store.getState().auth.user;
//   //   data.facilityId = facilityId

//   fetch(route, {
//     method: "DELETE",
//     headers: { "Content-Type": "application/json" },
//     body: data ? JSON.stringify(data) : null,
//   })
//     .then(function (response) {
//       // if the status of the response is greater than 400, then error is returned
//       if (response.status >= 400) {
//         if (err_cb) {
//           err_cb();
//         }
//       }
//       if (callback) {
//         callback();
//       }
//     })
//     .catch(function (err) {
//       return err_cb(err);
//     });
// };

// const _updateApi = (url, data, success, error) => {
//   //   const { facilityId } = store.getState().auth.user;
//   //   data.facilityId = facilityId
//   fetch(`${url}`, {
//     method: "PUT",
//     headers: {
//       "Content-Type": "application/json",
//     },
//     body: JSON.stringify(data),
//   })
//     .then(function (response) {
//       if (response.status >= 400) {
//         error(response);
//       } else {
//         success();
//       }
//     })
//     .catch((err) => error(err));
// };

// export { _fetchApi, _postApi, _deleteApi, _updateApi };

export const accessData = [
  {
    head: {
      name: "Dashboard",
      icon: (
        <MdDashboard size={25} style={{ marginRight: 5, paddingLeft: 7 }} />
      ),
      route: "/app/dashboard",
    },
    subitem: [],
  },
  {
    head: {
      name: "Purchase",
      icon: <FaCartPlus size={25} style={{ marginRight: 5, paddingLeft: 7 }} />,
      route: "/app/purchase",
    },
    subitem: [
      {
        name: "Purchase list",
        icon: <FaArchway size={26} style={{ marginRight: 5 }} />,
        route: "/app/purchase/purchase-list",
      },
      {
        name: "Suppliers",
        icon: <FaArchway size={26} style={{ marginRight: 5 }} />,
        route: "/app/purchase/suppliers",
      },
    ],
  },
  {
    head: {
      name: "Customers",
      icon: <GiPayMoney size={20} style={{ marginRight: 5 }} />,
      route: "/app/customer",
    },
    subitem: [
      {
        name: "Customers",
        icon: <FaArchway size={26} style={{ marginRight: 5 }} />,
        route: "/app/customer/index",
      },
    ],
  },
  {
    head: {
      name: "Sales",
      icon: (
        <span
          className="mr-1 font-weight-bold"
          style={{ marginRight: 5, paddingLeft: 7, fontSize: 18 }}
        >
          ₦{" "}
        </span>
      ),
      route: "/app/sales?tab=Make Sales",
    },
    subitem: [
      { name: "Make sales", route: 0 },
      { name: "Transfer form", route: 1 },
      { name: "Return items", route: 2 },
      { name: "Receive form", route: 3 },
      { name: "Pending sales", route: 4 },
    ],
  },
  {
    head: {
      name: "Settings",
      icon: <AiFillSetting size={20} style={{ marginRight: 5 }} />,
      route: "/app/setting",
    },
    subitem: [
      {
        name: "Unit of measurement",
        icon: <AiFillSetting size={26} style={{ marginRight: 5 }} />,
        route: "/app/setting/unit-measurement",
      },
    ],
  },
  {
    head: {
      name: "Reports",
      icon: <AiFillBook size={20} style={{ marginRight: 5 }} />,
      route: "/app/report",
    },
    subitem: [
      {
        name: "Generate invoice",
        icon: <AiFillBook size={26} style={{ marginRight: 5 }} />,
        route: "/app/report/invoice-report",
      },
      {
        name: "Expenses report",
        icon: <AiFillExperiment size={26} style={{ marginRight: 5 }} />,
        route: "/app/report/expenses",
      },
    ],
  },
  {
    head: {
      name: "Account",
      icon: <AiFillBook size={20} style={{ marginRight: 5 }} />,
      route: "/app/account",
    },
    subitem: [
      {
        name: "Record expenses",
        icon: <AiFillBook size={26} style={{ marginRight: 5 }} />,
        route: "/app/account/new",
      },
      {
        name: "Generate invoices",
        icon: <FaRegFileAlt size={26} style={{ marginRight: 5 }} />,
        route: "/app/account/generateinvoice",
      },
      {
        name: "Cash movement",
        icon: <AiOutlineSwap size={26} style={{ marginRight: 5 }} />,
        route: "/app/account/clickforcashmovement",
      },
      {
        name: "Generate account Report",
        icon: <AiFillSnippets size={26} style={{ marginRight: 5 }} />,
        route: "/app/Account/generateaccountreport",
      },
      {
        name: "Opening balance",
        icon: <MdAccountBalanceWallet size={26} style={{ marginRight: 5 }} />,
        route: "/app/account/opening-balance",
      },
      {
        name: "Client account statement",
        icon: <MdAccountBalance size={26} style={{ marginRight: 5 }} />,
        route: "/app/account/chientaccountstatement",
      },
      {
        name: "Setup chart of account",
        icon: <AiOutlinePartition size={26} style={{ marginRight: 5 }} />,
        route: "/app/account/setup-chart-of-account",
      },
      {
        name: "Account review",
        icon: <AiOutlineInsertRowAbove size={26} style={{ marginRight: 5 }} />,
        route: "/app/account/account-review",
      },
      {
        name: "Manage staff",
        icon: <AiOutlineTeam size={26} style={{ marginRight: 5 }} />,
        route: "/app/account/org-staff",
      },
      {
        name: "Payment voucher",
        icon: <AiOutlineCreditCard size={26} style={{ marginRight: 5 }} />,
        route: "/app/account/payment-voucher",
      },
      {
        name: "Memo",
        icon: <AiFillReconciliation size={26} style={{ marginRight: 5 }} />,
        route: "/app/account/memo-list",
      },
      {
        name: "Memo approval",
        icon: <AiFillReconciliation size={26} style={{ marginRight: 5 }} />,
        route: "/app/account/memo/approval",
      },
      {
        name: "Memo review",
        icon: <AiFillReconciliation size={26} style={{ marginRight: 5 }} />,
        route: "/app/account/memo/reviewal",
      },
    ],
  },
  {
    head: {
      name: "Admin",
      icon: <FaUserAlt size={25} style={{ marginRight: 5, paddingLeft: 7 }} />,
      route: "/app/admin",
    },
    subitem: [
      {
        name: "Manage users",
        icon: (
          <FaUsers size={26} fontWeight="bold" style={{ marginRight: 10 }} />
        ),
        route: "/app/admin/manage-users",
      },
      {
        name: "Manage stores",
        icon: (
          <FaStore size={26} fontWeight="bold" style={{ marginRight: 10 }} />
        ),
        route: "/app/admin/manage-stores",
      },
    ],
  },
];
