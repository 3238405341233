import React, { useCallback, useEffect, useState } from "react";
import CustomCard from "../components/CustomCard";
import {
  Alert,
  Badge,
  Button,
  CardBody,
  Col,
  Collapse,
  Input,
  Label,
  Row,
} from "reactstrap";
import CustomTable from "../../components/CustomTable";
import { useSelector } from "react-redux";
import { _fetchApi, _postApi } from "../../redux/actions/api";
import { GiCancel } from "react-icons/gi";
import { FaCheck, FaEdit, FaEye, FaViber } from "react-icons/fa";
import CustomButton from "../../app/components/Button";
import moment from "moment";
import CustomModal from "../components/CustomModal";
import { View, Text } from "@react-pdf/renderer";
import Loading from "../../app/components/Loading";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { _customNotify, _warningNotify } from "../../redux/helper";
import useQuery from "../hooks/useQuery";

function MemoList() {
  const activeBusiness = useSelector((state) => state.auth.activeBusiness);
  const { user } = useSelector((state) => state.auth);
  const [loading, setLoading] = useState(false);
  const [loading2, setLoading2] = useState(false);
  const [memos, setMemos] = useState([]);
  const [items, setItems] = useState({});
  const [items2, setItems2] = useState({});
  const [isOpen, setIsOpen] = useState(false);
  const [isOpen2, setIsOpen2] = useState(false);
  const [isOpen3, setIsOpen3] = useState(false);
  const [selectedMemoId, setSelectedMemoId] = useState(null);
  const [logs, setLogs] = useState([]);

  const toggleCollapse = (memoId) => {
    setSelectedMemoId(memoId);
    setIsOpen3(!isOpen3);
    getLogs(memoId);
  };

  const _form = {
    date: moment().format("YYYY-MM-DD"),
    purpose: "",
    from: "",
  };
  const [form, setForm] = useState(_form);

  const getMemos = useCallback(() => {
    setLoading(true);
    _fetchApi(
      `/account/get-memo/${activeBusiness.id}/pending/${user.id}/others`,
      (data) => {
        setLoading(false);
        if (data.success) {
          setMemos(data?.results);
        }
      },
      (err) => {
        setLoading(false);
        console.log(err);
      }
    );
  }, [activeBusiness.id, user.id]);

  useEffect(() => {
    getMemos();
  }, [getMemos]);

  const handleChange = ({ target: { name, value } }) => {
    setForm((p) => ({ ...p, [name]: value }));
  };

  const handleEdit = () => {
    setLoading2(true);
    // console.log(form)
    _postApi(
      "/account/update-memo",
      {
        ...form,
        logStatus: "approved",
      },
      (res) => {
        if (res.success) {
          _customNotify("Successfully Submit");
          setLoading2(false);
          history.goBack();
        }
      },
      (err) => {
        _warningNotify("error occured");
        console.log(err);
        setLoading2(false);
      }
    );
  };

  const toggle = (item) => {
    setItems(item);
    setIsOpen(!isOpen);
  };

  const toggle2 = (item) => {
    setItems2(item);
    setForm((p) => ({
      ...p,
      purpose: item.purpose,
      from: item.from_name,
      busName: item.from_name,
      memo_id: item.memo_id,
    }));
    setIsOpen2(!isOpen2);
  };

  const fields = [
    {
      title: "Date",
      custom: true,
      component: (item) => (
        <div className="text-left">{moment().format(item.date)}</div>
      ),
    },
    {
      title: "Memo No.",
      custom: true,
      component: (item) => <div className="text-center">{item.memo_id}</div>,
    },
    {
      title: "From",
      custom: true,
      component: (item) => <>{item.from_name}</>,
    },
    {
      title: "Status",
      custom: true,
      component: (item) => (
        <div className="text-center">
          <Badge color="danger">{item.status}</Badge>
        </div>
      ),
    },
    {
      title: "Action",
      custom: true,
      component: (item) => (
        <div className="text-center">
          <CustomButton
            color="success"
            size="sm"
            className="m-1"
            handleSubmit={() => {
              toggle(item);
            }}
          >
            <FaEye size="20" />
          </CustomButton>
          {item.status === "Rejected" ? (
            <CustomButton
              color="success"
              size="sm"
              className="m-1 ml-2"
              handleSubmit={() => {
                console.log(item);
                toggle2(item);
              }}
            >
              <FaEdit size="20" />
            </CustomButton>
          ) : null}
        </div>
      ),
    },
  ];

  const tableFields = [
    {
      title: "Name",
      custom: true,
      component: (item) => <div className="text-left">{item.name}</div>,
    },
    {
      title: "Ref. Number",
      custom: true,
      component: (item) => <div className="text-center">{item.id_link}</div>,
    },
    {
      title: "Remark",
      custom: true,
      component: (item) => <>{item.remark}</>,
    },
  ];

  const getLogs = useCallback((memoId) => {
    if (!memoId) return;
    _fetchApi(
      `/account/get-logs?id=${memoId}`,
      (data) => {
        setLoading(false);
        if (data.success) {
          setLogs(data.results[0]);
        }
      },
      (err) => {
        setLoading(false);
        console.log(err);
      }
    );
  }, []);

  useEffect(() => {
    getLogs();
  }, [getLogs]);

  const history = useHistory();
  return (
    <CustomCard header="Memos">
      <CustomButton
        size="sm"
        color="primary"
        className="mb-1"
        onClick={() => {
          history.push("/app/account/memo");
        }}
      >
        Add new memo
      </CustomButton>
      <Row>
        {loading && <Loading />}
        {!loading ? (
          <CustomTable data={memos} fields={fields} />
        ) : (
          <center>
            <Alert className="mt-3" color="info">
              No data to view
            </Alert>
          </center>
        )}
      </Row>

      <CustomModal isOpen={isOpen} toggle={toggle} header="Preview">
        <div>
          <div
            style={{
              borderWidth: 1,
              borderColor: "#000",
              padding: 20,
              marginBottom: 20,
            }}
          >
            <h2
              style={{
                fontSize: 18,
                textAlign: "center",
                marginBottom: 10,
                textTransform: "uppercase",
              }}
            >
              {activeBusiness.business_name}
            </h2>
            <h4
              style={{
                fontSize: 12,
                textAlign: "center",
                textTransform: "uppercase",
              }}
            >
              Internal Memo
            </h4>
          </div>

          <div style={{ marginBottom: 20 }}>
            <div style={{ flexDirection: "row", width: "100%" }}>
              <div
                style={{
                  marginBottom: 10,
                  fontSize: 12,
                  textTransform: "uppercase",
                  marginRight: 10,
                }}
              >
                The Managing Director,
              </div>
            </div>
            <div style={{ flexDirection: "row", width: "100%" }}>
              <div
                style={{
                  marginBottom: 10,
                  fontSize: 12,
                  textTransform: "uppercase",
                  marginRight: 10,
                }}
              >
                From: <b>{items?.from_name}</b>
              </div>
            </div>
            <div style={{ flexDirection: "row", width: "100%" }}>
              <div
                style={{
                  marginBottom: 10,
                  fontSize: 12,
                  textTransform: "uppercase",
                  marginRight: 10,
                }}
              >
                Date: <b>{moment().format(items?.date)}</b>
              </div>
            </div>
          </div>

          <div
            style={{ flexDirection: "column", width: "100%", marginBottom: 60 }}
          >
            <div style={{ flexDirection: "row", width: "100%" }}>
              <div
                style={{
                  marginBottom: 10,
                  fontSize: 14,

                  textTransform: "uppercase",
                  marginRight: 10,
                }}
              >
                Purpose: <b>{items?.purpose}</b>
              </div>
            </div>
          </div>
        </div>
      </CustomModal>
      <CustomModal isOpen={isOpen2} toggle={toggle2} header="Review rejected">
        <Row>
          <Col md={6}>
            <Label>From</Label>
            <Input
              type="text"
              name="from"
              value={items2?.from_name}
              disabled={true}
            />
          </Col>
          <Col md={6}>
            <Label>Date</Label>
            <Input
              type="date"
              name="date"
              value={moment().format(form.date)}
              disabled={true}
            />
          </Col>
          <Col md={12}>
            <Label>Purpose</Label>
            <Input
              onChange={handleChange}
              name="purpose"
              value={form.purpose}
              type="textarea"
            />
          </Col>
          <Col md={12} className="mt-3">
            <div>
              <CustomButton
                onClick={() => toggleCollapse(items2?.memo_id)}
                className="mb-2"
              >
                {isOpen3 ? "Hide" : "Show"} memo log
              </CustomButton>
              <Collapse isOpen={isOpen3}>
                <CustomCard>
                  <CustomTable
                    data={logs}
                    fields={tableFields}
                    className="mb-0"
                  />
                </CustomCard>
              </Collapse>
            </div>
          </Col>
          {/* {JSON.stringify(items2)} */}
        </Row>
        <center>
          <CustomButton
            loading={loading2}
            className="mt-3"
            onClick={handleEdit}
          >
            Submit
          </CustomButton>
        </center>
      </CustomModal>
    </CustomCard>
  );
}

export default MemoList;
