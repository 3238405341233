import React, { useState } from "react";
import {
  Button,
  Card,
  CardBody,
  CardHeader,
  Col,
  Container,
  Input,
  Label,
  Row,
  Table,
} from "reactstrap";
import CustomButton from "../../app/components/Button";
import CustomCard from "../../components/CustomCard";
import SideBar from "../Account/SideBar";
import PredefinedDateRanges from "../../app/DatePicker";
import { Link, useHistory } from "react-router-dom/cjs/react-router-dom";
import CommonSelect from "../../app/CommonSelect";
import {
  invoiceNumber,
  paymentMethod,
  transactionDate,
} from "../../app/selectOption";
import Datatable from "../../app/DataTable";
import { useSelector } from "react-redux";

export default function GenerateInvoice() {
  const _form = {
    expenditure_type: "",
    date: "",
    select_source_account: "",
    collected_by: "",
    total_amount: "",
    mode_of_payment: "",
    narrition: "",
  };

  const history = useHistory();

  const [form, setForm] = useState(_form);
  const [data, setData] = useState([]);

  const handleChange = ({ target: { name, value } }) => {
    setForm((p) => ({ ...p, [name]: value }));
  };

  const createInvoice = () => {
    history.push("/app/account/generateinvoice/invoice");
  };

  const activeBusiness = useSelector((state) => state.auth.activeBusiness);

  const columns = [
    {
      title: "Invoice Number",
      dataIndex: "invoiceNumber",
      sorter: (a, b) => a.invoiceNumber.length - b.invoiceNumber.length,
      render: (text) => (
        <Link
          to="#"
          className="link-primary"
          data-bs-toggle="modal"
          data-bs-target="#view_invoice"
        >
          {text}
        </Link>
      ),
    },
    {
      title: "Date",
      dataIndex: "date",
      sorter: (a, b) => a.date.length - b.date.length,
    },
    {
      title: "Description",
      dataIndex: "description",
      sorter: (a, b) => a.description.length - b.description.length,
    },
    {
      title: "Amount",
      dataIndex: "amount",
      sorter: (a, b) => a.amount.length - b.amount.length,
    },
    {
      title: "Payment Method",
      dataIndex: "paymentMethod",
      sorter: (a, b) => a.paymentMethod.length - b.paymentMethod.length,
    },
    {
      title: "Due Date",
      dataIndex: "dueDate",
      sorter: (a, b) => a.dueDate.length - b.dueDate.length,
    },
    {
      title: "Status",
      dataIndex: "status",
      sorter: (a, b) => a.status.length - b.status.length,
      render: (status) => (
        <>
          <span
            className={`badge d-inline-flex align-items-center badge-soft-success
            ${
              status === "Paid"
                ? "badge-soft-success"
                : status === "Overdue"
                ? "badge-soft-warning"
                : status === "Pending"
                ? "badge-soft-info"
                : ""
            }`}
          >
            <i className="ti ti-circle-filled fs-5 me-1" />
            {status}
          </span>
        </>
      ),
    },
    {
      title: "Action",
      dataIndex: "action",
      sorter: (a, b) => a.id.length - b.id.length,
      render: () => (
        <>
          <div className="dropdown">
            <Link
              to="#"
              className="btn btn-white btn-icon btn-sm d-flex align-items-center justify-content-center rounded-circle p-0"
              data-bs-toggle="dropdown"
              aria-expanded="false"
            >
              <i className="ti ti-dots-vertical fs-14" />
            </Link>
            <ul className="dropdown-menu dropdown-menu-right p-3">
              <li>
                <Link
                  className="dropdown-item rounded-1"
                  to="#"
                  data-bs-toggle="modal"
                  data-bs-target="#view_invoice"
                >
                  <i className="ti ti-menu me-2" />
                  View Invoice
                </Link>
              </li>
              <li>
                <Link
                  className="dropdown-item rounded-1"
                  to="/edit-invoice" // Replace with your route
                >
                  <i className="ti ti-edit-circle me-2" />
                  Edit
                </Link>
              </li>
              <li>
                <Link
                  className="dropdown-item rounded-1"
                  to="#"
                  data-bs-toggle="modal"
                  data-bs-target="#delete-modal"
                >
                  <i className="ti ti-trash-x me-2" />
                  Delete
                </Link>
              </li>
            </ul>
          </div>
        </>
      ),
    },
  ];

  return (
    <div>
      {/* <SideBar /> */}
      <Row>
        <CustomCard header="Generate Invoice">
          <CardBody className="p-0">
            <div className="card">
              <div className="card-header d-flex align-items-center justify-content-between flex-wrap">
                <h4 className="">Invoices List</h4>
                <div className="d-flex align-items-center flex-wrap">
                  <Button
                    to="#"
                    onClick={createInvoice}
                    className="btn d-flex align-items-center me-2"
                    style={{
                      backgroundColor: activeBusiness?.primary_color,
                      color: activeBusiness.secondary_color,
                      marginRight: "7px",
                    }}
                  >
                    Add Invoices
                  </Button>

                  {/* <div className="input-icon-start mb-3 me-2 position-relative">
                    <PredefinedDateRanges />
                  </div> */}

                  <div className="dropdown me-2">
                    <Link
                      to="#"
                      className="btn btn-outline-light bg-white dropdown-toggle"
                      data-bs-toggle="dropdown"
                      data-bs-auto-close="outside"
                    >
                      <i className="ti ti-filter me-2" />
                      Filter
                    </Link>
                    <div className="dropdown-menu drop-width">
                      <form>
                        <div className="d-flex align-items-center border-bottom p-3">
                          <h4>Filter</h4>
                        </div>
                        <div className="p-3 pb-0 border-bottom">
                          <div className="row">
                            <div className="col-md-6">
                              <div className="mb-3">
                                <label className="form-label">
                                  Invoice Number
                                </label>
                                <CommonSelect
                                  className="select"
                                  options={invoiceNumber}
                                  defaultValue={invoiceNumber[0]}
                                />
                              </div>
                            </div>
                            <div className="col-md-6">
                              <div className="mb-3">
                                <label className="form-label">Date</label>
                                <CommonSelect
                                  className="select"
                                  options={transactionDate}
                                  defaultValue={transactionDate[0]}
                                />
                              </div>
                            </div>
                            <div className="col-md-12">
                              <div className="mb-3">
                                <label className="form-label">
                                  Payment Method
                                </label>
                                <CommonSelect
                                  className="select"
                                  options={paymentMethod}
                                  defaultValue={paymentMethod[0]}
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="p-3 d-flex align-items-center justify-content-end">
                          <Link to="#" className="btn btn-light me-3">
                            Reset
                          </Link>
                          <button type="submit" className="btn btn-primary">
                            Apply
                          </button>
                        </div>
                      </form>
                    </div>
                  </div>
                  <div className="dropdown">
                    <Link
                      to="#"
                      className="btn btn-outline-light bg-white dropdown-toggle"
                      data-bs-toggle="dropdown"
                    >
                      <i className="ti ti-sort-ascending-2 me-2" />
                      Sort by A-Z
                    </Link>
                    <ul className="dropdown-menu p-3">
                      <li>
                        <Link to="#" className="dropdown-item rounded-1 active">
                          Ascending
                        </Link>
                      </li>
                      <li>
                        <Link to="#" className="dropdown-item rounded-1">
                          Descending
                        </Link>
                      </li>
                      <li>
                        <Link to="#" className="dropdown-item rounded-1">
                          Recently Viewed
                        </Link>
                      </li>
                      <li>
                        <Link to="#" className="dropdown-item rounded-1">
                          Recently Added
                        </Link>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
              <div className="card-body p-0 py-3">
                {/* Invoice List */}
                <Datatable
                  dataSource={data}
                  columns={columns}
                  Selection={true}
                />
                {/* /Invoice List */}
              </div>
            </div>
          </CardBody>
        </CustomCard>
      </Row>
    </div>
  );
}
