import React, { useCallback, useEffect, useState } from "react";
import {
  Document,
  Image,
  Page,
  PDFViewer,
  StyleSheet,
  View,
  Text,
  Font,
} from "@react-pdf/renderer";

import DM_SANS_NORMAL from "../../assets/img/DM_Sans/DM_Sans/static/DMSans_24pt-SemiBold.ttf";
import DM_SANS_BOLD from "../../assets/img/DM_Sans/DM_Sans/static/DMSans_24pt-Bold.ttf";
import DM_SANS_ITALIC from "../../assets/img/DM_Sans/DM_Sans/static/DMSans-Italic.ttf";
import { _fetchApi, toWordsconver } from "../../redux/actions/api";
import { useSelector } from "react-redux";
import useQuery from "../hooks/useQuery";

Font.register({
  family: "DM_SANS",
  fonts: [
    { src: DM_SANS_NORMAL, fontWeight: 700 },
    {
      src: DM_SANS_BOLD,
      fontStyle: "bold",
    },
    {
      src: DM_SANS_ITALIC,
      fontStyle: "italic",
    },
  ],
});
export default function PaymentVoucherPdf() {
  const activeBusiness = useSelector((state) => state.auth.activeBusiness);
  const [loading, setLoading] = useState(false);
  const query = useQuery();
  const id = query.get("id");
  const [data, setData] = useState({});

  const businessName = activeBusiness.business_name.toUpperCase();

  const getMemos = useCallback(() => {
    _fetchApi(
      `/account/get-memo-by-id/${activeBusiness.id}/Approval/${id}`,
      (data) => {
        setLoading(false);
        if (data.success) {
          setData(data.results[0]);
          alert(JSON.stringify(data));
        }
      }
    );
  }, [activeBusiness.id, id]);
  useEffect(() => {
    getMemos();
  }, [getMemos]);
  let amount = data?.amount;
  return (
    <PDFViewer
      style={{
        width: "100%",
        height: "97vh",
      }}
    >
      <Document>
        <Page style={styles.page}>
          {/* Header Section */}
          <View style={styles.header}>
            <Text>{businessName}</Text>
          </View>
          <View style={styles.title}>
            <Text>PAYMENT VOUCHER</Text>
          </View>

          {/* Form Section */}
          <View style={styles.section}>
            <View
              style={{ flexDirection: "row", justifyContent: "space-between" }}
            >
              <Text style={styles.label}>PV NO.</Text>
              <Text style={styles.input}>{data?.pv_number}</Text>
              <Text style={styles.label}>Date:</Text>
              <Text style={styles.input}>{data?.date}</Text>
            </View>
          </View>

          <View style={styles.section}>
            <View
              style={{ flexDirection: "row", justifyContent: "space-between" }}
            >
              <Text style={styles.label}>Cheque No:</Text>
              <Text style={styles.input}></Text>
              <Text style={styles.label}>Amount (₦):</Text>
              <Text style={styles.input}>
                {typeof amount === "number" ? "number" : 10}
              </Text>
            </View>
          </View>

          <View style={styles.section}>
            <Text style={styles.label}>Amount in words:</Text>
            <Text style={styles.input}>
              {/* {" "}
              {toWordsconver(amount.split(".")[0])?.toUpperCase()}
              NAIRA
              {amount.split(".")[1] !== "00"
                ? ` and ${toWordsconver(amount.split(".")[1])} kobo`
                : null}{" "} */}
              ONLY
            </Text>
            <Text style={styles.label}>Payee:</Text>
            <Text style={styles.input}></Text>
            <Text style={styles.label}>Purpose:</Text>
            <Text style={styles.input}>{data?.purpose}</Text>
            <Text style={styles.input}></Text>
          </View>

          <View style={styles.section}>
            <Text style={styles.label}>Payment requisitioned by:</Text>
            <Text style={styles.input}></Text>
            <Text style={styles.label}>Cheque received by:</Text>
            <View
              style={{ flexDirection: "row", justifyContent: "space-between" }}
            >
              <Text style={styles.label}>Name:</Text>
              <Text style={styles.input}></Text>
              <Text style={styles.label}>Sign & Date:</Text>
              <Text style={styles.input}></Text>
            </View>
          </View>

          {/* Approval Section */}
          <View style={styles.section}>
            <Text style={styles.label}>Checked/approved by:</Text>
            <Text style={styles.input}></Text>
            <Text style={styles.label}>
              Supporting documentation & other details attached/checked:
            </Text>
            <Text style={styles.input}></Text>
            <Text style={styles.label}>
              Addition/calculations of attached documentation checked:
            </Text>
            <Text style={styles.input}></Text>
            <Text style={styles.label}>Payment reviewed:</Text>
            <Text style={styles.input}></Text>
            <Text style={styles.label}>Payment approved:</Text>
            <Text style={styles.input}></Text>
          </View>

          {/* Stores Ledger Account Table */}
          {/* <View style={{ marginBottom: 15 }}>
            <Text
              style={{
                fontSize: 18,
                marginTop: 10,
                fontFamily: "DM_SANS",
                fontWeight: "bold",
                textAlign: "center",
                textDecoration: "underline",
              }}
            >
              STORES LEDGER ACCOUNT
            </Text>
            <View
              style={{
                borderWidth: 1,
                borderColor: "#000",
                width: "100%",
                height: 100,
              }}
            >
              <View
                style={{
                  flexDirection: "row",
                  width: "100%",
                  borderBottomWidth: 1,
                }}
              >
                <Text
                  style={{
                    borderRightWidth: 1,
                    borderRightColor: "#000",
                    padding: 5,
                    width: "11%",
                    textAlign: "center",
                    fontFamily: "DM_SANS",
                    fontWeight: "light",
                    textTransform: "uppercase",
                    fontSize: 12,
                  }}
                >
                  Date
                </Text>
                <Text
                  style={{
                    borderRightWidth: 1,
                    borderRightColor: "#000",
                    padding: 5,
                    width: "20%",
                    textAlign: "center",
                    fontFamily: "DM_SANS",
                    fontWeight: "light",
                    textTransform: "uppercase",
                    fontSize: 12,
                  }}
                >
                  Particulars
                </Text>
                <Text
                  style={{
                    borderRightWidth: 1,
                    borderRightColor: "#000",
                    padding: 5,
                    width: "23%",
                    textAlign: "center",
                    fontFamily: "DM_SANS",
                    fontWeight: "light",
                    textTransform: "uppercase",
                    fontSize: 12,
                  }}
                >
                  IN
                </Text>
                <Text
                  style={{
                    borderRightWidth: 1,
                    borderRightColor: "#000",
                    padding: 5,
                    width: "23%",
                    textAlign: "center",
                    fontFamily: "DM_SANS",
                    fontWeight: "light",
                    textTransform: "uppercase",
                    fontSize: 12,
                  }}
                >
                  OUT
                </Text>
                <Text
                  style={{
                    // borderRightWidth: 1,
                    borderRightColor: "#000",
                    padding: 5,
                    width: "23%",
                    textAlign: "center",
                    fontFamily: "DM_SANS",
                    fontWeight: "light",
                    textTransform: "uppercase",
                    fontSize: 12,
                  }}
                >
                  Balance
                </Text>
              </View>

              <View
                style={{
                  flexDirection: "row",
                  width: "100%",
                  borderBottomWidth: 1,
                }}
              >
                <Text
                  style={{
                    borderRightWidth: 1,
                    borderRightColor: "#000",
                    padding: 5,
                    width: "11%",
                    textAlign: "center",
                    fontFamily: "DM_SANS",
                    fontWeight: "light",
                    textTransform: "uppercase",
                    fontSize: 12,
                  }}
                ></Text>
                <Text
                  style={{
                    borderRightWidth: 1,
                    borderRightColor: "#000",
                    padding: 5,
                    width: "20%",
                    textAlign: "center",
                    fontFamily: "DM_SANS",
                    fontWeight: "light",
                    textTransform: "uppercase",
                    fontSize: 12,
                  }}
                ></Text>
                <Text
                  style={{
                    borderRightWidth: 1,
                    borderRightColor: "#000",
                    padding: 5,
                    width: "7.6%",
                    textAlign: "center",
                    fontFamily: "DM_SANS",
                    fontWeight: "light",
                    textTransform: "uppercase",
                    fontSize: 9,
                  }}
                >
                  QTY
                </Text>
                <Text
                  style={{
                    borderRightWidth: 1,
                    borderRightColor: "#000",
                    padding: 5,
                    width: "7.7%",
                    textAlign: "center",
                    fontFamily: "DM_SANS",
                    fontWeight: "light",
                    textTransform: "uppercase",
                    fontSize: 9,
                  }}
                >
                  Cost
                </Text>
                <Text
                  style={{
                    borderRightWidth: 1,
                    borderRightColor: "#000",
                    padding: 5,
                    width: "7.7%",
                    textAlign: "center",
                    fontFamily: "DM_SANS",
                    fontWeight: "light",
                    textTransform: "uppercase",
                    fontSize: 9,
                  }}
                >
                  Value
                </Text>
                <Text
                  style={{
                    borderRightWidth: 1,
                    borderRightColor: "#000",
                    padding: 5,
                    width: "7.6%",
                    textAlign: "center",
                    fontFamily: "DM_SANS",
                    fontWeight: "light",
                    textTransform: "uppercase",
                    fontSize: 9,
                  }}
                >
                  QTY
                </Text>
                <Text
                  style={{
                    borderRightWidth: 1,
                    borderRightColor: "#000",
                    padding: 5,
                    width: "7.7%",
                    textAlign: "center",
                    fontFamily: "DM_SANS",
                    fontWeight: "light",
                    textTransform: "uppercase",
                    fontSize: 9,
                  }}
                >
                  Cost
                </Text>
                <Text
                  style={{
                    borderRightWidth: 1,
                    borderRightColor: "#000",
                    padding: 5,
                    width: "7.7%",
                    textAlign: "center",
                    fontFamily: "DM_SANS",
                    fontWeight: "light",
                    textTransform: "uppercase",
                    fontSize: 9,
                  }}
                >
                  Value
                </Text>
                <Text
                  style={{
                    borderRightWidth: 1,
                    borderRightColor: "#000",
                    padding: 5,
                    width: "11.5%",
                    textAlign: "center",
                    fontFamily: "DM_SANS",
                    fontWeight: "light",
                    textTransform: "uppercase",
                    fontSize: 9,
                  }}
                >
                  QTY
                </Text>
                <Text
                  style={{
                    // borderRightWidth: 1,
                    borderRightColor: "#000",
                    padding: 5,
                    width: "11.5%",
                    textAlign: "center",
                    fontFamily: "DM_SANS",
                    fontWeight: "light",
                    textTransform: "uppercase",
                    fontSize: 9,
                  }}
                >
                  Value
                </Text>
              </View>
            </View>
          </View> */}
        </Page>
      </Document>
    </PDFViewer>
  );
}

const styles = StyleSheet.create({
  page: {
    padding: 20,
  },
  header: {
    backgroundColor: "#DDEEFF",
    padding: 10,
    textAlign: "center",
    fontSize: 17,
    fontWeight: "bold",
  },
  title: {
    backgroundColor: "#2E86C1",
    color: "white",
    textAlign: "center",
    fontSize: 10,
    padding: 8,
    marginBottom: 10,
  },
  table: {
    display: "table",
    width: "auto",
    borderStyle: "solid",
    borderColor: "#bfbfbf",
    borderWidth: 1,
    marginBottom: 10,
  },
  tableRow: {
    flexDirection: "row",
  },
  tableCol: {
    borderStyle: "solid",
    borderColor: "#bfbfbf",
    borderWidth: 1,
    padding: 5,
    flexGrow: 1,
  },
  label: {
    fontSize: 10,
    marginTop: 10,
  },
  input: {
    fontSize: 10,
    borderBottom: 1,
    borderColor: "#000",
    marginBottom: 10,
    marginTop: 10,
  },
  section: {
    marginBottom: 15,
  },
});
