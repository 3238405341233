import React, { useState } from "react";
// import { LoadingSM } from '../../loading';
// import { createUser } from '../../../redux/actions/auth';
import NewUserForm from "./NewUserForm";
// import { _postApi, apiURL } from "../../redux/actions/api";
// import CustomButton from '../components/Button'
import { _customNotify, _warningNotify } from "../../redux/helper";
import CustomButton from "../components/Button";
import CustomCard from "../../components/CustomCard";
import {
  BUSINESS_TYPES,
  moduleData,
  productsModuleData,
  servicesModuleData,
  subModuleData,
} from "../../constants";
import { useDispatch } from "react-redux";
import { signup } from "../../redux/actions/auth";
import useQuery from "../../inventria/hooks/useQuery";
import { useSelector } from "react-redux";
import { useHistory } from "react-router";
import { accessData } from "../../redux/actions/api";

const SignUp = () => {
  const query = useQuery();
  const dispatch = useDispatch();
  const activeBusiness = useSelector((state) => state.auth.activeBusiness);

  const userType = query.get("type") || "new_admin";

  const [form, setForm] = useState({
    username: "",
    email: "",
    phone: "",
    fullname: "",
    password: "",
    confirmPassword: "",
    busName: "",
    address: "",
    branch_name: "",
    accessTo: moduleData,
    // functionality: subModuleData,
    functionalities: subModuleData,
    businessType: BUSINESS_TYPES.PRODUCTS,
  });
  // const options = useSelector((state) => state.auth);
  const [signupLoading, setSignupLoading] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const [createUserError, setCreateUserError] = useState("");
  const [multiSelections, setMultiSelections] = useState([]);

  let multipleStore = multiSelections.map((i) => i.branch_name);
  const history = useHistory();
  const resetForm = () => {
    setForm({
      username: "",
      email: "",
      phone: "",
      password: "",
      confirmPassword: "",
      busName: "",
      address: "",
      businessType: "PRODUCTS",
    });
  };

  const goHome = () => {
    resetForm();
    setSignupLoading(false);
    // navigation.navigate('Home')
  };

  const handleSubmit = () => {
    if (form.phone === "") {
      if (form.store === "") {
        _warningNotify("please select store");
      } else {
        _warningNotify("Incomplete form");
      }
      // } else if (!dSame) {
      //   Alert.alert('Unmatch password!')
    } else {
      setSignupLoading(true);
      const error = () => setSignupLoading(false);

      let accs =
        form.businessType === "PRODUCTS"
          ? productsModuleData
          : form.businessType === "SERVICES"
          ? servicesModuleData
          : [...productsModuleData, ...servicesModuleData];
      // form.branch_name = activeBusiness.business_name;
      form.busName = activeBusiness.business_name;
      form.accessTo = [...moduleData, ...accs];
      form.store = multipleStore.toString();
      form.fullname = form.fullname.toString();
      // if(activeBusiness.id) {
      //   form.facility = activeBusiness.id
      dispatch(
        signup(
          form,
          (resp) => {
            setSignupLoading(false);
            history.push("/app/admin/manage-users");
            _customNotify("User created successfully");
          },
          error,
          userType
        )
      );
    }
  };

  const handleChange = ({ target }) => {
    setForm((p) => ({
      ...p,
      error: "",
      [target.name]: target.value,
    }));
  };
  const handleTypeaheadChange = (val) => {
    if (val) {
      setForm((p) => ({
        ...p,
        branch_name: val.storeName,
      }));
    }
  };

  // resetForm = () => {
  //   this.setState({
  //     fullname: '',
  //     username: '',
  //     phone: '',
  //     password: '',
  //     role: '',
  //     accessTo: [],
  //   })
  // }

  // handleSubmit = (e) => {
  //   this.setState({ loading: true })
  //   e.preventDefault()
  //   const { fullname, username, phone, password, role, accessTo } = this.state
  //   if (
  //     fullname === ''
  //     // username === '' ||
  //     // phone === '' ||
  //     // password === '' ||
  //     // role === ''
  //   ) {
  //     this.setState({ error: 'Please complete the form' })
  //   } else {
  //     const data = {
  //       fullname,
  //       username,
  //       phone,
  //       password,
  //       role,
  //       accessTo: accessTo.join(','),
  //     }
  //     let callback = () => {
  //       console.log('success')
  //       this.setState({ loading: false })
  //       _customNotify('User created successfully!')
  //       this.resetForm()
  //     }
  //     let error = () => {
  //       console.log('error')
  //       this.setState({ loading: false })
  //     }
  //     _postApi(`${apiURL}/auth/sign-up`, data, callback, error)
  //     // console.log(data)
  //   }
  // }

  const handleCheckboxChange = (item) => {
    setForm((prevForm) => {
      const newAccessTo = prevForm.accessTo.includes(item.head.name)
        ? prevForm.accessTo.filter((access) => access !== item.head.name)
        : [...prevForm.accessTo, item.head.name];

      let newFunctionalities = prevForm.functionalities.filter(
        (func) => !item.subitem?.map((subItem) => subItem.name).includes(func)
      );

      return {
        ...prevForm,
        accessTo: newAccessTo,
        functionalities: newFunctionalities,
      };
    });
  };

  const handleChildChechBoxChange = (subItem) => {
    setForm((prevForm) => {
      // Handle functionality changes (child checkboxes)
      const updatedFunctionalities = prevForm.functionalities.includes(
        subItem.name
      )
        ? prevForm.functionalities.filter((func) => func !== subItem.name)
        : [...prevForm.functionalities, subItem.name];

      return {
        ...prevForm,
        functionalities: updatedFunctionalities,
      };
    });
  };

  // render() {
  //   const {
  //     handleChange,
  //     handleSubmit,
  //     handleCheckboxChange,
  //     state: { fullname, username, phone, password, role, accessTo, loading },
  //     props: { createUserError },
  //   } = this
  const formIsValid =
    form.phone !== "" && form.password !== "" && form.accessTo.length;

  return (
    <CustomCard back header={"Create User Account"}>
      {/* {JSON.stringify(form)} */}
      <NewUserForm
        // fullname={fullname}
        username={form.username}
        form={form}
        phone={form.phone}
        handleTypeaheadChange={handleTypeaheadChange}
        password={form.password}
        role={form.role}
        fullname={form.fullname}
        accessTo={form.accessTo}
        handleChange={handleChange}
        handleCheckboxChange={handleCheckboxChange}
        handleChildChechBoxChange={handleChildChechBoxChange}
        userType={userType}
        multiSelections={multiSelections}
        setMultiSelections={setMultiSelections}
        accessData={accessData}
      />

      {createUserError !== "" ? (
        <center>
          <p style={{ color: "red" }}>{createUserError}</p>
        </center>
      ) : null}

      <CustomButton
        disabled={!formIsValid}
        loading={signupLoading}
        type="submit"
        className="offset-md-5 col-md-2 offset-lg-5 col-lg-2"
        onClick={handleSubmit}
      >
        Create User
      </CustomButton>
    </CustomCard>
  );
  // }
};

// function mapStateToProps({ auth }) {
//   return {
//     creatingUser: auth.creatingUser,
//     createUserError: auth.createUserError,
//   };
// }

// function mapDispatchToProps(dispatch) {
//   return {
//     createUser: (data) => dispatch(createUser(data)),
//   };
// }

export default SignUp;
